/* ==========
    Webfonts
   ========== */

  // Font loading approach: https://www.filamentgroup.com/lab/font-events.html
  // http://bdadam.com/blog/loading-webfonts-with-high-performance.html
  @font-face {
    font-family: 'Dax';
    src: url('../assets/fonts/DaxPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Dax';
    src: url('../assets/fonts/DaxPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Clarendon';
    src: url('../assets/fonts/Superclarendon.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url('../assets/fonts/sourcesanspro-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url('../assets/fonts/sourcesanspro-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

/* =================
    Base Typography 
   ================= */

  body {
    font-family: Helvetica, Arial, sans-serif;
    font-size: $base-font-size;
  }

  // Heading sizes are loosely based on the Fibonacci Sequence.
  // 0, 1, 1, 2, 3, 5, 8...
  // 0 in the sequence is used for font-size of the body above (1 + 0).

  h1 {
    font-family: $font-dax;
    font-size: $base-font-size * 1.8; // 1 + 0.8
    font-size: $base-font-size * 2.6; // 1 + 0.8
  }
  h2 {
    font-family: $font-dax;
    font-size: $base-font-size * 1.5; // 1 + 0.5
    font-size: $base-font-size * 2.2; // 1 + 0.8
    font-size: $base-font-size * 1.8; // 1 + 0.5
  }
  h3 {
    font-family: $font-dax;
    font-size: $base-font-size * 1.3; // 1 + 0.3
    font-size: $base-font-size * 1.8; // 1 + 0.5
    font-size: $base-font-size * 1.4; // 1 + 0.3
  }
  h4 {
    font-size: $base-font-size * 1.2; // 1 + 0.2
    font-size: $base-font-size * 1.4; // 1 + 0.3
    font-size: $base-font-size * 1.2; // 1 + 0.2
  }
  h5 {
    font-size: $base-font-size * 1.1; // 1 + 0.1 (twice!)
    //font-size: $base-font-size * 1.2; // 1 + 0.2
  }
  h6 {
    font-size: $base-font-size; // 1 + 0.1 (twice!)
  }
  
  body, h1, h2, h3, h4, h5, h6, p {
    line-height: 1.35em;
  }
  
  h1, h2, h3, h4, h5, h6, p {
    margin-bottom: 0;
  }
  
  h1, h2, h3 {
    font-weight: normal;
  }

  p, ul {
    margin-bottom: 0;
  }

  hr {
    border: 0; 
    color: $color-grey;
    background-color: $color-grey;
    height: 1px;
  }

/* =============
    Blockquotes
   ============= */

  blockquote {
    background: $color-grey-light;
    border-left: 0.25em solid $color-brand-primary;
    font-size: 1.125em;
    font-style: italic;
    margin: 1em 2em;
    padding: 0.75em;

    *:first-child {
      margin-top: 0;
    }

    footer {
      font-size: 0.875rem;
      font-style: normal;
      margin-top: 0.5em;
    }
  }

/* =======
    Lists
   ======= */

  ul, ol {
    padding: 0 0 0 1.5em;
  }

  li {
    line-height: 1.5em;
    margin-top: 0;
    padding: 0;
  }

  ol li li {
    list-style-type: lower-alpha;
  }

  ol li li li {
    list-style-type: lower-roman;
  }

  .list--unstyled {
    list-style-type: none;
    padding: 0;
  }

  .list--check {
    list-style-type: none;

    li {
      position: relative;

      &:before{
        background-image: url("../assets/icons/check-purple.svg");
        background-size: cover;
        content: "";
        display: inline-block;
        height: 8px;
        left: -1.25em;
        position: absolute;
        top: 0.5em;
        width: 10px;
      }
    }
  }

  .list--check-green li:before{
    background-image: url("../assets/icons/check-green.svg");
  }

  .intro.list--check li {

    line-height: 1.33em;
    margin-bottom: 0.5em;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      height: 12px;
      top: 0.325em;
      width: 15px;
    }
  }

  .aside .list--check li:before {
    background-image: url("../assets/icons/check-white.svg");
  }

  .aside--sidebar .list--check li:before {
    background-image: url("../assets/icons/check-purple.svg");
  }

  dl {
    margin-bottom: 0;
  }

  dd {
    margin: 0.5em 0 0 1em;
  }

  .list--faqs,
  .list--collapsible {

    padding: 0;
    list-style-type: none;

    > li {
      border: 1px solid $color-grey;
      border-radius: $radius;
      margin-bottom: 1em;
      padding: 1em 1em 0 1em;
    }

    .item--heading {
      font-weight: bold;
      margin: 0;
    }

    dl {
      margin-left: 1em;
    }

  }

  .list--faqs dl {
    margin-bottom: 1em;
  }

  .js .list--collapsible {

    .item--heading {
      cursor: pointer;
    }

    dl {
      margin-bottom: 1em;
    }

  }

/* =================
    Inline Elements
   ================= */

  /* Emphais */

    /*em {
      font-style: italic;
    }

    strong {
      font-style: normal;
      font-weight: bold;
    }*/

  /* Links & Anchors */

    a,
    .link {
      color: $color-brand-primary;
      cursor: pointer;
      text-decoration: underline;

      &:visited {
        color: $color-brand-primary-dark;

      }

      &:hover {
        color: $color-brand-primary-light;

      }

      &:active {
        color: $color-brand-secondary-dark;

      }
    }

/* ==============
    Text Effects
   ============== */

  .intro {
    font-size: 1.3125em;
  }

  .alignleft {
    float: left;
    margin: 1em 1em 1em 0;
  }

  .alignright {
    float: right;
    margin: 1em 0 1em 1em;
  }

