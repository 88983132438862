  .content {
    margin-bottom: 1em;
    width: 100%;

    > footer {
      border-top: 1px solid $color-grey;
    }

  }

  .contentgrid + .content {
    clear: left;
  }

  .article__heading {
    margin-top: 0;
  }


/* ======
    Blog
   ====== */

  .post {

    border-bottom: 1px solid $color-grey;
    margin-bottom: 1em;
    padding-bottom: 1em;

    .meta {
      font-size: 0.875em;
      font-style: italic;
      margin-top: 0.5em;
    }

    .media:last-child {
      border-bottom: none;
    }

  }

  .post h1 {
    line-height: 1.1em;
    margin-top: 0;
  }

  .post + .sidebar--right {
    float: none;
  }

  @media screen and (min-width: 30em){
    .post + .sidebar--right {
      width: 100%;
    }
  }

  @media screen and (min-width: 50em){

    .post {
      float: left;
      width: calc(100% - #{$width-postsidebar});
    }

    .post + .sidebar--right {
      float: right;
      margin-right: 0;
      margin-left: 1em;
      width: $width-sidebar;
    }
  }

  @media screen and (min-width: 57em){
    .post {
      width: calc(100% - 17em);
    }
    .post + .sidebar--right {
      width: 16em;
    }
  }

  @media screen and (min-width: 64em){
    .post {
      width: calc(100% - 21em);
    }
    .post + .sidebar--right {
      width: 20em;
    }
  }

  article > h2:first-child {
    margin-top: 0;
  }


/* =============
    Sales Pitch
   ============= */

  .pitch {
    background: #fcf7ff;
    border: 1px solid $color-brand-primary;
    padding: 1em;
    *:first-child {
      margin-top: 0;
    }
  }