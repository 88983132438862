.contentgrid {
	margin-top: 0;
	margin-bottom: 1em;
	overflow: hidden;
}

.contentgrid__item {
	background: $color-grey-light;
	display: block;
	float: left;
	margin: 1em 0 0;
	overflow: hidden;
	position: relative;
	width: 100%;

	p {
		
		margin-top: 0.5em;

		&:first-of-type {
			margin-top: 0;
		}

		&.contentgrid__price {
			margin-top: 0.1em;
		}

	}

	img {
		margin-top: 0;
	}

	&--imagebg {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;

		.contentgrid__content {
			background: $color-grey-light;
			background: rgba($color-white, 0.85);
		}

		.contentgrid__action {
			padding-top: 3em;
		}

	}
}

.contentgrid__heading {
	width: 100%;
}

.contentgrid__title {
	background: $color-brand-primary-dark;
	font-size: 1em;
	line-height: 1.5em;
	margin-top: 0;
	padding: 0.5em 0.5em 0.25em;
	a {
		color: $color-white;
		text-decoration: none;
	}
}

img + .contentgrid__content > .contentgrid__text {
	margin-top: 0;
}

.contentgrid__content {
	margin-bottom: 0;

	img {
		height: auto;
	}

}

.contentgrid__content,
.contentgrid__action {
	margin-top: 0;
	overflow: hidden;
	padding: 0.5em;
}

.contentgrid__title {
	margin-top: 0;
}

.contentgrid__price {
	color: $color-brand-primary;
	font-weight: bold;
	font-size: 2em;
	line-height: 1em;
}

	.price__from {
		font-size: 1rem;
	}

	.caveat {
		font-size: 1rem;
		top: -0.75em;
	}


.contentgrid__action {
	padding-top: 0;
	position: relative;
	
	.btn {
		margin-top: 0.5em;

		+ .readmore {
			bottom: 0.75em;
			position: absolute;
			right: 1em;
		}
	}

}

.contentgrid--related {
	
	border-top: 1px solid $color-grey;
	clear: left;
	margin-top: 1em;
	max-width: 48em;

	.contentgrid__title {
		background: none;
	}

}

@media screen and (min-width: 27.5em){
	.contentgrid--related {

		.contentgrid__item {
			margin-right: 1em;
			width: calc(50% - 0.5em);

			&:nth-of-type(2n) {
				margin-right: 0;
			}

			&:nth-of-type(2n+1) {
				clear: left;
			}
		}

	}
}

@media screen and (min-width: 37.5em) {
	.contentgrid__item {
		margin-right: 1em;
		width: calc(50% - 0.5em);

		&:nth-of-type(2n) {
			margin-right: 0;
		}

		&:nth-of-type(2n+1) {
			clear: left;
		}
	}

}


@media screen and (min-width: 60em) {
	
	.contentgrid--related .contentgrid__item,
	.contentgrid__item {
		//width: calc(33.333333333% - 0.666666667em);
		width: calc(33.3% - 0.66em);

		&:nth-of-type(2n) {
			margin-right: 1em;
		}

		&:nth-of-type(2n+1) {
			clear: none;
		}

		&:nth-of-type(3n) {
			margin-right: 0;
		}

		&:nth-of-type(3n+1) {
			clear: left;
		}
	}
}
/*

@media screen and (min-width: 80em) {
	.contentgrid__item {
		width: calc(25% - 0.75em);

		&:nth-of-type(2n) {
			margin-right: 1em;
		}

		&:nth-of-type(2n+1) {
			clear: none;
		}

		&:nth-of-type(3n) {
			margin-right: 1em;
		}

		&:nth-of-type(3n+1) {
			clear: none;
		}

		&:nth-of-type(4n) {
			margin-right: 0;
		}

		&:nth-of-type(4n+1) {
			clear: left;
		}
	}
}*/

@supports (display: flex) {
	.contentgrid {
		display: flex; 
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	.contentgrid__item {
		display: flex; 
		flex-direction: column;
	}
	.contentgrid__item:last-of-type { 
		margin-right: 0;
	}
	.contentgrid__content {
		flex: 1 0 auto;
	}
	.contentgrid__item--imagebg {
		.contentgrid__action {
			flex: 1 0 auto;
			.btn {
				position: absolute;
				bottom: 0.5em;
			}
		}
		.contentgrid__content {
			flex: 0 0 auto;
		}
	}
}

