.siteheader {/*
	float: left;
	margin-bottom: 1em;
	width: 100%*/
}

.masthead {
	//overflow: hidden;
	float: left;
	padding: 0 1em 0;
	width: 100%;
	text-align: center;
}

/* ======
	Logo
   ====== */

	.logo {
		display: block;
		margin: 0 auto;
	}

	@media screen and (min-width: 32.5em) {

		.logo {
			margin-top: 1.5em;
			display: inline-block;
			float: left;
		}

	}

/* =====
		93%
	 ===== */

	.rating93 {
		display: none;
	}

	@media screen and (min-width: 36.25em) {

		.rating93 {
			display: inline-block;
			height: 25px;
			margin: 0.5em auto 0;
		}

	}

	@media screen and (min-width: 50.0625em) {

		.rating93 {
			height: 30px;
			margin-top: 1.65em;
		}

	}

	@media screen and (min-width: 53.75em) {

		.rating93 {
			height: auto;
			margin-top: 1.5em;
		}

	}
	


/* =========
	Call Us
   ========= */

	.callus {
		display: inline-block;
		margin: 1em auto 0;
		position: relative;
		text-align: center;
		//float: right;
	}

	.callus__title,
	.callus__tel,
	.callus__tagline,
	.callus__viewtimes {
		margin: 0;
	}

	.callus__title,
	.callus__tel {
		color: $color-brand-primary;
	}

	.callus__tel {
		font-size: 2.75em;
		font-weight: bold;
		line-height: 1em;

		sup {
			font-size: 0.4em;
			top: -1.1em;
		}
	}

	.callus__opentimes {
		background: $color-brand-primary-dark;
		color: $color-white;
		//display: block;
		display: none;
		margin-top: 0.5em;
    //opacity: 0;
		padding: 1em;
		position: absolute;
		text-align: left;
		right: 0;
		z-index: 999;

		.callus__title {
			color: $color-brand-secondary;
		}

		p {
			font-weight: bold;
		}

		ul {
			margin-top: 0.25em;
		}

		&:after {
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-bottom: 7px solid $color-brand-primary-dark;
			content: '';
			height: 0; 
			position: absolute;
			right: 2em;
			top: -6px;
			width: 0; 
		}
	}

	@media screen and (min-width: 32.5em) {

		.callus {
			float: right;
			margin: 0;
			text-align: right;
		}

	}

/* ============
		View Times 
	 ============ */

	.callus__viewtimes:hover + .callus__opentimes {
		display: block;/*
  	animation-duration: 0.3s;
  	animation-fill-mode: forwards;
  	animation-name: fadeInDown;*/
		//display: block;
	}


/* ================
		JS Enhancements
	 ================ */

	@media screen and (max-width: 50em) {
		
		.js .masthead {
			/*padding: 1em 0 0;*/
			padding: 0 0 1em;
		}

		.js .logo {
			display: inline-block;
			float: left;
			margin: 0.25em 0 0;
			width: 7em;
		}

		.js .callus {
			clear: both;
			float: left;
			margin-top: 0.5em;
			//text-align: center;
			width: 100%;

			display: inline-block;
			margin: 0.5em auto 0;
		}

		.jstoggle {
			background-color: $color-grey;
			background-position: 0.5em center;
			background-repeat: no-repeat;
			color: $color-white;
			cursor: pointer;
			margin-top: 0.3em;
			border-radius: $radius;
			display: inline-block;
			float: right;
			font-family: $font-dax;
			height: 2.25em;
			line-height: 2.4em;
			margin-left: 0.35em;
			margin-top: 0;
			padding: 0 0.5em 0 2em;
			text-transform: uppercase;

			img {
				margin-right: 0.35em;
			}

			&--callus {
				background-image: url('../assets/icons/call.svg');
				background-color: $color-action-primary;
			}

			&--nav__list {
				background-image: url('../assets/icons/menu.svg');
				background-color: $color-action-secondary;
				padding-left: 2.25em;
			}
		}

		.jstoggle-close {
			background-image: url('../assets/icons/close.svg');
		}

	}