.banner {
  background-color: $color-brand-primary-dark;
  color: $color-white;
  overflow: hidden;
  padding: 1.5em;
  position: relative;
  width: 100%;

  &--hero {
    padding: 1.5em 1.5em 5.5em;

    .btn {
      position: absolute;
      bottom: 1em;
      left: 1em;
      width: calc(100% - 2em);
    }


    @media screen and (min-width: 26.25em) {
      .btn {
        width: auto;
      }
    }

  }

  p {
    text-shadow: $shadow;
  }

  a {
    color: $color-white;
  }
}

.banner__title,
.banner__exclaimation {
  font-family: $font-clarendon;
  max-width: 20em;
}

.banner__title {
  font-size: 1.8em;
  font-size: 1.5em;
  margin-top: 0;
    text-shadow: $shadow;
}

.banner__exclaimation {
  color: $color-brand-tertiary;
  font-size: 1.75em;
  margin: 0.75em 0;
    text-shadow: $shadow;
}

.banner__text {
  font-family: $font-dax;
  text-shadow: $shadow;
  font-size: 1.35em;

  + .banner__text {
    margin-top: 0.5em;
  }
}

.btn--banner {
  display: inline-block;
  font-size: 1.25em;
  margin-top: 1.25em;
  padding: 0.7em 1.5em;

  &:hover{
    padding: 0.75em 1.5em 0.65em;
  }

  &:active {
    padding: 0.8em 1.5em 0.6em;
  }
}

.banner__content {
  margin-top: 0;
  position: relative;
  z-index: 10;
}

.banner__image {
  bottom: 0;
  display: none;
  height: 100%;
  position: absolute;
  right: 0;

  &--hero {
    height: auto;
    max-width: 500px;
    width: 60%;
  }
}

@media screen and (min-width: 37.5em) {

  .banner--sureyoucan:after {
      background: url('../assets/sureyoucan-white.png') center center no-repeat;
      background-size: cover;
      content: '';
      position: absolute;
      bottom: 2px;
      right: 2px;
      height: 100px;
      width: 100px;
  }

  .banner__title {
    font-size: 1.8em;
  }

  .banner__exclaimation {
    font-size: 2em;
  }

  .banner__text {
    //font-size: 1.125em;
    margin-right: 50%;
  }

  .banner__image {
    display: block;
  }

}

@media screen and (min-width: 64em) {

  .banner--sureyoucan:after {
      right: 20em;
      height: 137px;
      width: 138px;
  }

  .banner__title,
  .banner__exclaimation  {
    max-width: 30em;
  }

}