
.contenthub {
  margin-top: 0;

  .contentgrid {
    border-bottom: 1px solid $color-grey;
    margin-bottom: 1em;
    padding-bottom: 1em;
  }
}

.contenthub + .sidebar--right {
  margin-top: 0;
}

@media screen and (min-width: 50em) {

  .contenthub {
    width: calc(100% - #{$width-postsidebar});
  }

  .contenthub + .sidebar--right {
    margin-left: 1em;
    margin-right: 0;
    margin-top: 1em;

    .form__newsletter {

      input[type=email] {
        margin-top: 0;
        width: 100%;
      }

      .btn {
        float: right;
        margin-top: 0.5em;
      }

    }

  }

}

@media screen and (min-width: 57em){

  .contenthub {
    width: calc(100% - #{$width-postsidebar});
  }

  .contenthub + .sidebar--right {
    width: calc(#{$width-postsidebar} - 1em);
  }

}