.media {
  border-bottom: 1px solid $color-grey;
  overflow: hidden;
  padding-bottom: 1em;
  position: relative;
  zoom: 1;

  > *:first-child {
    margin-top: 0;
  }

  a {
    color: $color-link;

    &:hover {
      color: $color-link-hover;
    }

  }

  &--standout {
    background: $color-grey-light;
    border-bottom: none;
    padding: 1em;
  }

  &--pitch {
    border: 1px solid #6F2C91;
    background: #fcf7ff;
    clear: both;
    padding: 1em;

    .media__heading {
      margin-top: 0;
    }

  }

  &--feedback {
    background: $color-grey-light;
    border-bottom: none;
    padding: 1em;

    .media__heading {
      text-transform: uppercase;
    }
  }

  .review__rating {
    display: inline-block;
    margin: 0.5em 0;
  }

}

.media__image {
  display: none;
}

.media__heading {
  font-size: 1.2em;
}

.media__date {
  font-size: 0.875em;
  font-style: italic;
  margin-top: 0.25em;
}

.media__body {
  margin-top: 0.425em;
}

@media screen and (min-width: 30em) {

  .media--withimage {
    padding-left: calc(120px + 0.5em);
    min-height: 7.5625em;
  }

  .media--withavatar {
    padding-left: calc(100px + 1em);
    min-height: 8.25em;
  }

  .media--standout.media--withavatar {
    padding-left: calc(100px + 2em);
  }

  .media__image {
    display: block;
    left: 0;
    margin: 0 0.5em 0.25em 0;
    position: absolute;
    top: 0;
    width: auto;
  }

  .media--standout.media--withavatar .media__image {
    left: 1em;
    top: 1em;
  }

  .media__heading {
    font-size: 1.2em;
    margin-top: 0;
  }

}

/* Media Objects in Sidebar */

  .sidebar .media {
    border: none;
    margin: 0 0.5em 0.25em;
    padding-bottom: 0;
  }

  .sidebar .media__heading {
    font-size: 0.875em;
    margin-top: 0.125em;
  }

  .sidebar__list .media__date {
    font-size: 0.75em;
  }

  @media screen and (min-width: 30em) {

    .sidebar .media {
      min-height: calc(0.5em + 45px);
    }

    .sidebar .media--withimage {
      padding-left: calc(72px + 0.5em);
    }

    .sidebar .media__image {
      margin: 0.25em 0.5em 0.25em 0;
    }
    
  }