.sg-header {
  display: none;
  overflow: hidden;
  padding: 0 0 1em;
  img {
    float: left;
  }
}

.sg-logo-link { 
  background: $color-brand-primary;
  display: block;
  width: 100%;
}

@media screen and (min-width: 40em){
  .sg-logo {
    float: left;
  }
}

.sg-menu {
  margin-top: 0;
  ul {
    background: #F4EFF7;
    list-style-type: none;
  }
}

  .sg-nav__list {
    display: block;
    margin: 0;
    padding: 0;
    //height: 2.75em;
    padding-bottom: 0.75em;
    text-align: left;

    a {
      display: inline-block;
      text-decoration: none;
      &:focus {
        box-shadow: none;
      }
    }
  }

  .sg-nav__item {
    display: inline-block;
    font-size: 0.75em;
    font-weight: bold;    
    margin: 0 0.75em;
    padding-top: 1em;
    position: relative;
    text-transform: uppercase;

    &:first-child {
      margin-left: 1em;
    }

    &:last-child {
      margin-right: 1em;
    }

    &:hover {
      cursor: pointer;
      > ul {
        display: block;
      }
    }

    ul {
      display: none;
      font-weight: normal;
      position: absolute;
      top: 2.75em;
      left: -1em;
      padding: 0;
      padding-bottom: 1em;
      text-transform: none;
      z-index: 999;
    }

    li {
      white-space: nowrap;

    }

    li a {
      padding: 0.5em 1em 0;
      &:hover {
        text-decoration: underline;
      }
    }

  }

  @media screen and (min-width: 40em){

    .sg-nav__list {
      height: 2.75em;
      padding-bottom: 0;
    }

    .sg-nav__item {
      margin-left: 1em;
      line-height: 4em;
      padding-top: 0;

      &:first-child {
        margin-left: 1em;
      }

      &:last-child {
        margin-right: 0;
      }

      ul {
        top: 3em;
      }

    }

    .sg-nav__item:before {
      content: '';
      position: relative;
      top: 7px;
      display: inline-block;
      width: 25px;
      height: 25px;
      margin-right: 0.375em;
      background-repeat: no-repeat;
    }

    .sg-nav__item--core:before {
      background-image: url('../assets/styleguide/core.svg');
    }
    .sg-nav__item--atoms:before {
      background-image: url('../assets/styleguide/atoms.svg');
    }
    .sg-nav__item--molecules:before {
      background-image: url('../assets/styleguide/molecules.svg');
    }
    .sg-nav__item--organisms:before {
      background-image: url('../assets/styleguide/organisms.svg');
    }
    .sg-nav__item--templates:before {
      background-image: url('../assets/styleguide/templates.svg');
    }
    .sg-nav__item--pages:before {
      background-image: url('../assets/styleguide/pages.svg');
    }

  }


.sg-container {
  //font-family: $font-body;
  float: left;
  margin: 0 auto;
  padding: 1em;
  width: 100%;
  //width: calc(100% - 200px);
}

.sg-wrapper {
  clear: left;
  margin: 0;
}

.sg-component {
  margin: 0 0 2em 0;
}

.sg-component__body {
  margin-top: 0;
}

.sg-component__header {
  padding: 1.5em;
  background: #f7f7f7;
  border-bottom: 1px solid #ececec;
}

.sg-display {
  border: 1px solid $color-grey;
  clear: both;
  margin: 2em 0 1em;
  overflow: auto;
  zoom: 1;
}

.sg-component__display {
  margin-top: 1em;
  padding-bottom: 1em;

  &--navigation {
    height: 35em;
  }
}

.sg-component__render {
  font-family: $font-body;
  padding: 0 1em 1em;

  .sg-h6 {
    background: $color-brand-primary;
    color: $color-white;
    margin-bottom: 1em;
    margin-top: 1em;
    padding: 0.25em 0.5em;

    &:not(:first-child){
      margin-top: 2.5em;
    }
  }
}

.sg-component__source,
.sg-component__docs {
  pre {
    margin-bottom: 0;
  }
}

.sg-component__source {
  font-size: 0.875em;
}

.sg-component__docscontent {
  padding: 0 1em 1em;

  h3, h4, h5, h6, p, li {
    font-family: 'Helvetica', 'Arial', sans-serif;
    max-width: 45em;
  }

  code {
    background: $color-grey-light;
    border: 1px solid $color-grey;
    font-size: 0.875em;
    padding: 0.1em 0.3em;
  }
}

.sg-component__title,
.sg-component__toggle {
  margin: 0;
  padding: 0;
  font: 600 0.75rem/1.2 'Helvetica', 'Arial', sans-serif;
  text-transform: uppercase;
  color: #333;
}

.sg-component__title {
  background: #f7f7f7;
  border-bottom: 1px solid #ececec;
  padding: 1em;
  max-width: 100%;
}

.sg-component__sass {
  margin: .5em 0 0;
  padding: 0;
  font: 400 italic 0.75rem/1.2 'Helvetica', 'Arial', sans-serif;
}

.sg-h1 {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  line-height: 1.2em;
  margin-bottom: 1.5em;
  font-size: 2rem;
  text-transform:uppercase;
}

.sg-h2 {
  font: bold 100%/1.2 'Helvetica', 'Arial', sans-serif;
  font-size: 1.5rem;
  margin-top: 1em;
  position: relative;
  text-transform:uppercase;
}

.sg-h3,
.sg-component__docscontent h3,
.sg-h4,
.sg-component__docscontent h4,
.sg-h5,
.sg-component__docscontent h5,
.sg-h6,
.sg-component__docscontent h6 {
  font:bold 100%/1.2 'Helvetica', 'Arial', sans-serif;
  margin-bottom: 0;
  font-size: 1.25rem;

  + p {
    margin-top: 0.5em;
  }
}

.sg-h4,
.sg-component__docscontent h4 {
  font-size: 1rem;
}

.sg-h5,
.sg-component__docscontent h5 {
  font-size: 0.875rem;
}

.sg-h6,
.sg-component__docscontent h6 {
  clear: left;
  float: left;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.sg-h1 {
  position: relative;
}

.sg-h1,
.sg-h2,
.sg-h3,
.sg-h4,
.sg-p {
  font-family: Helvetica, Arial, sans-serif;
  padding: 0;
}

.sg-palette {
  list-style-type: none;
  padding: 0;

  li {
    border-radius: $radius;
    display: inline-block;
    margin-bottom: 1em;
    margin-right: 1em;
    padding: 1em;
    text-align: center;
    width: 14em;
  }
}

.sg-example {
  border: 1px solid $color-grey;
  overflow: hidden;
  padding: 0 0.5em 1em;
}

.sg-card-container {
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 0;
}

.sg-card {
  display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  border: 1px solid $color-grey;
  border-radius: $radius;
  float: left;
  margin: 1em 1em 0 0;
  padding: 1em;
  width: 100%;
}

.sg-card-heading {
  margin-top: 0;
}

@media screen and (min-width: 45em){
  .sg-card {
    width: calc(50% - 1em);
  }
}

@media screen and (min-width: 60em){
  .sg-card {
    width: calc(33.3% - 1.5em);
  }
}

.js {
  .sg-toggle {
    border-bottom: none;
    cursor: pointer;

    &:before {
      content: "+ ";
      font-size: 1.5em;
    }
  }

  .sg-toggle-open {
    border-bottom: 1px solid $color-grey;
  }

  .sg-toggle-open:before {
      content: "– ";    
  }

}

.sg-status {
  border-radius: $radius;
  color: $color-white;
  display: inline-block;
  font-size: 0.5em;
  //margin-left: 1.5em;
  margin-top: 0.25em;
  padding: 0.25em 0.5em;
  position: absolute;
  left: 0;
  top: -2.5em;
  
  &--wip {
    background: $color-error;
  }
  
  &--beta {
    background: $color-warning;
    color: $color-text;
  }
  
  &--final {
    background: $color-success;
  }
}

@media screen and (min-wdith: 30em){

  .sg-h2 {
    margin-top: 0;
  }

}



