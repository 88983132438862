/* ======================
		Top Level Navigation
   ====================== */

	.nav__list {
		border-bottom: 2px solid $color-brand-primary;
		border-top: 1px solid $color-grey;
		clear: both;

		display: block;
		display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
		display: -ms-flexbox;  /* TWEENER - IE 10 */
		display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;

		float: left;
		list-style-type: none;
		margin: 1em auto;
		padding: 0;
		position: relative;
		text-align: center;
		//opacity: 0.9999999999;
		width: 100%;
	}

	.nav__item {

		display: inline-block;
		display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
		display: -ms-flexbox;  /* TWEENER - IE 10 */
		display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
		display: flex;

		font-size: 0.75em;
		font-weight: bold;
		position: relative;
		text-align: center;
		text-transform: uppercase;
	
	&:first-child {
		display: none;
	}

		&:hover{
			background: $color-brand-primary;
		}

		// Dropdown styling

		&--dropdown {
			position: relative;

			&:hover {

				> .nav__link {
					color: $color-white;
				}

				> .dropdown__menu {
					display: block;
					opacity: 1;
				}

				a.dropdown__trigger::after{
		    		background-image: url(../assets/icons/nav-arrow-hover.svg);
				}

			}

		}

	}

	a.nav__link {
		display: block;
		padding: 0.25em 0.65em;
		text-decoration: none;
		transition: 0.2s;

		&:first-child {
			padding-top: 0.7em;
		}

		&:link {
			color: $color-text;
		}

		&:visited {
			color: $color-text;    
		}

		&:hover{
			color: $color-white;
		}

		&:active{
			color: $color-white;
		}

		&--current:link,
		&--current:visited,
		&--current:hover,
		&--current:active {
			background: $color-brand-primary;
			color: $color-white;
		}

	}


/* ===============
    Dropdown Menu
   =============== */

	a.dropdown__trigger {
		padding: 0.7em 1.75em 0.7em 0.75em;
		position: relative;

		&:focus + .dropdown__menu {
			display: block;
			opacity: 1;
		}

		&::after {
			content: "";
    	background-image: url(../assets/icons/nav-arrow.svg);
    	background-size: 9px 7px;
    	background-repeat: no-repeat;
			position: absolute;
			color: $color-brand-secondary;
			font-size: 1.25em;
			font-weight: bold;
			top: 0.95em;
			right: 0.5em;
			width: 9px;
			height: 7px;
		}
	}

	/*.no-js a.dropdown__trigger:focus + .dropdown__menu {
		display: block;
		opacity: 1;
	}*/

	.dropdown__menu {
		background-color: $color-brand-primary;
		display: none;
		list-style-type: none;
		opacity: 0;
		padding: 0;
		position: absolute;
		left: 0;
		text-align: left;
		top: 1.75rem;
		transition: opacity 0.5s ease;
		z-index: 20;

		a.nav__link {
			color: $color-white;
		}
	}

	.nav__item--dropdown:last-child .dropdown__menu {
		left: auto;
		right: 0;
	}

	.dropdown__item {
		font-weight: normal;
		text-transform: none;
		white-space: nowrap;

		&:first-child a.nav__link {
			margin-top: 0.35em;
		}

		&:last-child a.nav__link {
			margin-bottom: 0.35em;
		}

		&:hover {
			text-decoration: underline;
		}
	}


@media screen and (min-width: 50em) {

	.nav__item {
		font-size: 0.75em;
	}

}

@media screen and (min-width: 54em) {

	.nav__item {
		font-size: 0.8125em;
	}

}

@media screen and (min-width: 58.25em) {

	.nav__item {
		font-size: 0.875em;
	}

}

@media screen and (min-width: 61.25em) {

	.nav__item {
		margin: 0 0.25em;
	}

}

@media screen and (min-width: 65.5em) {

	.nav__item {
		font-size: 0.9375em;
	}

}

@media screen and (min-width: 69.75em) {

	.nav__item:first-child {
		display: inherit;
	}

}

/*
@supports (display: flex) {

	.nav__list {
		display: -webkit-box;
		display: -ms-flexbox;  
		display: -webkit-flex; 
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		//vertical-align: middle
	}

	.nav__item {
		display: flex;
	}

}
*/


/* =========================
		Small screen navigation 
	 ========================= */
/*
	@media screen and (max-width: 50em) {
		
		.nav__list {
			display: none;
		}

	}*/


/* ============
		Pagination 
	 ============ */

	.pagination {
		text-align: center;
	}

	.pagination__list {
		display: block;
		list-style-type: none;
		//margin: 0 auto;
		padding: 0;
		overflow: hidden;

		.btn,
		.btn:hover,
		.btn:active,
		.btn.btn--active {
			margin-right: 0.25em;
			padding: 0.35em 0.5em;
			width: auto;
		}
	}

	.pagination__item {
		display: inline-block;
	}

	@media screen and (min-width: 32.5em){
		.pagination__list {
			.btn,
			.btn:hover,
			.btn:active,
			.btn.btn--active {
				margin-right: 0.5em;
				padding: 0.5em 0.7em;
			}
		}
	}


/* =================
		JS Enhancements
	 ================= */

	@media screen and (max-width: 50em) {

		.js {

			.nav__list {
		    margin: 0 auto 1em;
		  }

			.nav__item:not(:first-child) {
				border-top: 1px solid $color-grey;
			}

			.nav__item,
			.dropdown__menu,
			a.nav__link {
				width: 100%;
			}

			.nav__item {
				font-size: 0.9375em;
			}

			a.nav__link {
				margin-right: 2.5em;
				text-align: left;

				&:first-child {
					padding-top: 0.5em;
				}
			}

			.nav__item--dropdown:hover .dropdown__menu {
				display: none;
			}

			a.dropdown__trigger {

				&::after {
					display: none;
				}

				&:focus + .dropdown__menu {
					display: none;
				}

			}

			.jstoggle-expand {
				background-image: url(../assets/icons/nav-arrow.svg);
				background-position: center center;
				background-repeat: no-repeat;
				height: 2.5em;
				margin-top: 0;
				position: absolute;
				right: -2.5em;
				right: 0;
				top: 0;
				width: 2.5em;
			}

			.nav__item--dropdown:hover .jstoggle-expand {
				background-image: url(../assets/icons/nav-arrow-hover.svg);
			}

			.nav__item--dropdown:hover > .dropdown__menu {
				display: none;
			}

			.dropdown--active {
				background: $color-brand-primary;

				.jstoggle-expand {
					background-image: url(../assets/icons/nav-arrow-hover.svg);
				}

				> .nav__link {
					color: $color-white;
				}

				> .dropdown__menu {
					display: block;
				}
			}
		}
	}

	@media screen and (min-width: 50em) {
		
		.js a.dropdown__trigger:hover + .dropdown__menu {
			display: block;
			opacity: 1;
		}

	}

