/* ======================
  Base Message Styling
   ====================== */

  .alert,
  .bubble {
    border-radius: $radius;
    border-width: 1px;
    border-style: solid;
    clear: left;
    float: left;
    position: relative;
    max-width: $maxwidth-form;
    width: 100%;

    p {
      text-align: left;
    }
  }

  .input--small ~ .bubble {
    width: 10em;
  }

  .alert {

    p {
      font-size: 0.875rem;
      margin: 0.625em;
    }

    a {
      color: $color-text;
    }
  }

  .alert__title {
    font-size: 0.875rem;
    margin: 0.75em 0.625em;
    padding-left: 2em;
    position: relative;
    text-align: left;
  }

  .bubble {
    margin-top: 0.6em;

    p {
      font-size: 0.8125rem;
      margin: 0.625em;
    }

    &:before,
    &:after {
      border-left: 0.4em solid transparent;
      border-right: 0.4em solid transparent;
      content: '';
      height: 0;
      position: absolute;
      right: 0.7em;
      top: -0.7em;
      width: 0; 
    }

    &:after {
      top: -0.55em;
    }
  }

  .bubble--arrowleft {
    
    width: auto;

    &:before,
    &:after {
      right: auto;
      left: 0.7em;
    }
    
  }

/* =======================
  Error Message Styling
   ======================= */

  .bubble--error,
  .alert--error {
    background: $color-bg-error;
    border-color: $color-error;
    color: $color-error;
  }

  .bubble--error {
    &:before {
      border-bottom: 0.7em solid $color-error;
    }
    &:after {
      border-bottom: 0.7em solid $color-bg-error;
    }
  }

  .alert--error .alert__title:before {
    content: url(../img/icon-error.svg);
    height: 1em;
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
  }

/* =========================
  Success Message Styling
   ========================= */

  .bubble--success,
  .alert--success {
    background: $color-bg-success;
    border-color: $color-success;
    color: $color-success;
  }

  .bubble--success {
    &:before {
      border-bottom: 0.7em solid $color-success;
    }
    &:after {
      border-bottom: 0.7em solid $color-bg-success;
    }
  }

  .alert--success .alert__title:before {
    content: url(../img/icon-check.svg);
    height: 1em;
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
  }

/* ======================
  Info Message Styling
   ====================== */

  .bubble--info,
  .alert--info {
    background: $color-bg-info;
    border-color: $color-info;
    color: $color-info;
  }

  .bubble--info {
    &:before {
      border-bottom: 0.7em solid $color-info;
    }
    &:after {
      border-bottom: 0.7em solid $color-bg-info;
    }
  }

  .alert--info .alert__title:before {
    content: url(../img/icon-info.svg);
    height: 1em;
    position: absolute;
    top: -0.15em;
    left: 0;
    width: 1em;
  }

  .bubble--strength {
    margin-top: 1.2em;

    &:before,
    &:after {
      right: 2%;
    }

    @media screen and (min-width: 30em) and (min-height: 23.125em){
      margin-top: 1.1em;  

      &:before,
      &:after {
        right: 2.7%;
      }   
    }
  
  }

  /*.date-feedback {
    float: left;
    clear: left;
    //margin-top: 0.25em;
  }*/


