
.sitefooter {
	background: $color-bg-dark;
	clear: both;
	color: $color-white;
	overflow: hidden;
	padding: 0 0 1em;

	h1, h2, h3, h4, h5, h6, p {
		min-width: 100%;
	}

	a {
		color: $color-white;
	}

}

.sitefooter__asides {
	background: $color-brand-primary-dark;
	overflow: hidden;
}


// Footer Links

	.sitefooter__links {
    border-top: 1px solid rgba($color-white, 0.25);
		clear: both;
		overflow: hidden;
		padding: 0 2em 1em;
		margin: 0 0 1em;
	}

	.sitefooter__links,
	.sitefooter__links ul {
		list-style-type: none;
		width: 100%;
	}

	.sitefooter__links ul {
		padding: 0;
	}

	.sitefooter__heading {
		font-size: 1em;
	}

	.sitefooter__header {
		margin-top: 1.5em;

		> a {
		    font-family: $font-dax;
			text-transform: uppercase;
		}

		&:not(:first-of-type) {
			margin-top: 1.5em;
		}

	}

	@media screen and (min-width: 26.25em) {

		.sitefooter__links > li {
			float: left;
			width: 50%;
		}
	}

	@media screen and (min-width: 45em) {

		.sitefooter__links > li {
			width: 25%;
		}
	}

// Partners

	.sitefooter__partners {
    border-top: 1px solid rgba($color-white, 0.25);
		clear: both;
		margin-top: 0;
		padding: 1em 2em 1em;
		text-align: center;
		img {
			display: inline-block;
			margin: 1em 0.5em 0;
		}
	}
	

// Small print

	.sitefooter__smallprint {
    border-top: 1px solid rgba($color-white, 0.25);
		clear: both;
		padding: 0 1em;
		text-align: center;
		a {
			border-right: 1px solid rgba($color-white, 0.75);
			margin: 0 0.5rem 0 0;
			padding: 0 0.5rem 0 0;
			white-space: nowrap;

			&:last-of-type {
				border-right: none;
			}
		}
	}