.breadcrumb__list {
	display: block;
	float: left;
	font-size: 0.875em;
	list-style-type: none;
	margin-top: 0;
	padding: 0;
	width: 100%;
}

.banner + .breadcrumb__list {
	margin-top: 1em;
}

.breadcrumb__item {
	display: inline;
	margin-right: 1.5em;
	position: relative;

	&:not(:first-child):before {
		content: '〉';
		left: -1em;
		position: absolute;
		top: 0;
	}
}