@media screen and (max-width: 40em){
    %visuallyhidden {
        margin: -1px;
        padding: 0;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0 0 0 0);
        clip: rect(0, 0, 0, 0);
        position: absolute;
    }
}

    .progress {
        font-size: 0;
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;
        max-width: 40rem;
    }

    .progress__item {
        color: $color-grey-dark;
        display: inline-block;
        font-size: 1rem;
        margin: 0;
        text-align: center;
    }

    .progress__item.current {
        color: $color-success;

        .progress__num {
            background: $color-success;
            border: 1px solid $color-success;
            color: $color-white;

            &:before {
                border-top: 1px solid $color-success;
            }
        }
    }

    .progress__item.complete {
        color: $color-brand-primary;

        .progress__num {
            background: $color-brand-primary;
            border: 1px solid $color-brand-primary;
            color: $color-white;

            &:before {
                border-top: 1px solid $color-brand-primary;
            }
        }
    }

    .progress__num {
        background: white;
        border: 1px solid $color-grey-dark;
        border-radius: 50%;
        display: block;
        height: 2em;
        line-height: 1.9em;
        margin: 0 auto 0.25em;
        position: relative;
        text-align: center;
        width: 2em;

        &:before {
            border-top: 1px dashed $color-grey-dark;
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: -1;
        }

    }

    .progress__item:last-child .progress__num:before {
        border-top: none;
    }

    @media screen and (max-width: 40em){

        .progress__desc {
            @extend %visuallyhidden;
        }

    }

    // 3 Steps

    .progress--3steps {

        .progress__item {
            width: 33.3333333333%;
        }

        .progress__num:before {
            width: 27.5vw;

            @media screen and (min-width: 40em){
                width: 12.5em;
            }
        }

    }

    // 4 Steps

    .progress--4steps {

        .progress__item {
            width: 25%;
        }

        .progress__num:before {
            width: 20vw;

            @media screen and (min-width: 40em){
                width: 9em;
            }
        }

    }

    // 5 Steps

    .progress--5steps {

        .progress__item {
            width: 20%;
        }

        .progress__num:before {
            width: 17.5vw;

            @media screen and (min-width: 40em){
                width: 7em;
            }
        }

    }