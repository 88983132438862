/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}

body {
  //font-family: $font-body;
}

*, *:before, *:after {
  box-sizing: inherit;
}

* + * {
  margin-top: $vertical-rhythm;
}

main {
  clear: both;
}

:focus,
:focus:hover,
:focus:active,
.focus,
.focushover,
.focusactive {
  box-shadow: 0 0 10px $color-info;
  outline: none;
  z-index: 99;
}

.wrapper {
  font-family: $font-body;
  margin: 0 auto;
  max-width: $maxwidth;
  padding: 0 1em;
}

/* Images */

  img {
    max-width: 100%;
  }

  .img {

    &--avatar {
      border-radius: 50%;
    }

    &--hero {
      width: 960px;
    }

  }

  .img--right {
    display: block;
    margin: 1em auto 0;
  }

  @media screen and (min-width: 57em){

    .img--right {
      float: right;
      margin-bottom: 1em;
      margin-left: 1em;
    }

  }