/* ======================
    Content Choreography 
   ====================== */


  @supports (display: flex) {

    main {
      display: flex; 
      flex-direction: row;
      flex-wrap: wrap;

      .sidebar {
        order: 3;
      }

      .contentgrid {
        order: 1;
      }

      .content {
        order: 2;
      }

      .sidebar--right {
        order: 4;
      }

    }

    .dualsidebars {

      .sidebar--left {
        order: 4;
      }

      .sidebar--right {
        order: 3;
      }

      .contentgrid {
        order: 1;
      }

      .content {
        order: 2;
      }

    }

    @media screen and (min-width: 50em) {

      main {

        .sidebar {
          order: 1;
        }

        .contentgrid {
          order: 2;
        }

        .content {
          order: 3;
        }

        .sidebar--right {
          order: 4;
        }

      }

      .dualsidebars {

        .sidebar--left {
          order: 1;
        }

        .sidebar--right {
          order: 4;
        }

        .contentgrid {
          order: 2;
        }

        .content {
          order: 3;
        }

      }

    }

  }