/* ========
		Tables
	 ======== */

    table {
			border-collapse: collapse;
			border-spacing: 0;
      margin-bottom: 1em;
      width: 100%;
    }

    th,
    td {
			padding: 0.5em 1.5em 0.5em 0.5em;
			text-align: left;
		}

    tbody th {
    	font-weight: normal;
    }

    tbody th,
    tbody td {
      border-top: 1px solid $color-grey-dark;
    }

    tr:last-child {
        border-bottom: 1px solid $color-grey-dark;
    }

    tr:nth-child(even) th,
    tr:nth-child(even) td { 
        background-color: $color-grey-light;
    }

    @media screen and (max-width: 35em) {

        table {
            overflow-x: auto;
            display: block;
        }
    }

/* =======================
		Tables - Side-By-Side
	 ======================= */

  .sidebyside {

		th {
			background: #6F2C91;
			color: #fff;

			&:nth-child(odd) {
				background: lighten(#6F2C91,$color-variance);
			}
		}

		tbody th,
		tbody td {
		  border-top: none;
		}

		tr:last-child {
		    border-bottom: none;
		}

		td { 
			background-color: $color-grey-light;
			vertical-align: top;
		}

		td:nth-child(even) { 
			background-color: $color-grey;
		}

		&--amt {

			th {
				background: #F8641B;

				&:nth-child(odd) {
					background: lighten(#F8641B,$color-variance);
				}
			}

		}

		ul, ol {
			margin-top: 0;
		}

	}


/* ========================
		Quote Comparison Table
 	 ======================== */

	.comparison__table {
		display: table;
		margin-bottom: 0;
		overflow-x: visible;
		width: 100%;

		tr:nth-child(even) th,
		tr:nth-child(even) td {
		    background-color: $color-white;
		}

		tr:last-child {
			border-bottom: none;
		}

		th {
			border-left: 1px solid $color-grey-dark;
			border-top: 1px solid $color-grey-dark;
			text-align: right;
		}

		th, td {
			border-right: 1px solid $color-grey-dark;
			font-size: 0.875em;
			font-weight: normal;
			padding: 0.5em 0.75em;
		}

		td {
			text-align: center;
		}

		.btn {
			margin-top: 0;
			width: 100%;
		}

		img {
	    	margin: 0 auto;
	    	vertical-align: middle;
		}

		.comparison__price,
		.comparison__action {
			td {
				font-size: 1rem;
			}
		}

		.comparison__insurer{
			th:not(:first-child) {
				text-align: center;
			}
			td {
				padding: 0.25em;
			}
			img {
		    	max-width: 100px;
		    	width: 100%;
			}
		}

	}

	.comparison__table--2results,
	.comparison__table--3results {

		tr td:nth-child(2n) {
		    background-color: $color-grey-light;
		}

		.comparison__heading--section td:nth-child(2n) {
		    background-color: $color-white;
		}

		.comparison__insurer td:nth-child(2n) {
			background-color: $color-white;
		}

	}


	.comparison__heading--section {

		th {
			border-left: none;
			font-size: 0.75em;
			font-weight: bold;
			padding-bottom: 0;
			text-align: left;
			text-transform: uppercase;
			padding: 0.25em 0;
		}

		th, td {
			border-right: none;
			padding-top: 1em;
		}

	}

	.comparison__price td {
		font-weight: bold;
	}

	.comparison__action {

		th {
			border-left: none;
			border-bottom: none;
		}

		td {
			border-bottom: 1px solid $color-grey-dark;
		}

	}

	@media screen and (max-width: 31.25em) { 

		.comparison__table {	

			tr {
				display: block;
				float: left;
				margin-top: 0;
				position: relative;
				width: 100%;

				&:first-child td {
					border-bottom: none;
				}

			}

			th {
				border: none;
				display: none;
				padding-left: 0;
				position: absolute;
				top: 0;
				text-align: left;
				width: 100%;

				&.comparison__heading--cover {
					border-bottom: none;
					display: block;
					font-size: 0.75em;

					~ td {
						border-top: 1px solid $color-grey-dark;
						margin-top: 1.5em;
					}

				}

			}

			td {
				border-bottom: 1px solid $color-grey-dark;
				display: block;
				float: left;
				margin-top: 0;
				text-align: center;
				width: 33.333333333%;

				&:first-of-type {
					border-left: 1px solid $color-grey-dark;
				}

			}

			.comparison__action {
				display: block;
				margin-top: 1em;
			}
		}

		.comparison__heading--section {

			th {
				display: block;
				padding-top: 0.5em;
				padding-top: 1em;
			}

			td {
				display: none;

				&:first-of-type {
					margin-top: 2em;
				}

			}

			+ tr {
				margin-top: 1.75em;
			}
		}

		.comparison__action {

			td {
				border-top: 1px solid $color-grey-dark;
			}

		}

		@supports (transform: rotate(90deg)) and (transform-origin: left top 0) {

			.comparison__table {
				margin-left: 1em;
				width: calc(100% - 1em);
			}

			.comparison__heading--section {
				th {
					padding-left: 2.75em;
					padding-top: 0.25em;
					position: absolute;
					transform: rotate(90deg);
					transform-origin: left top 0;
				}

				+ tr {
					margin-top: 0.75em;
				}
			}
		}

	}
