/* ============
	Typography 
   ============ */

	$base-font-size: 1em;
	$vertical-rhythm: $base-font-size;

	$font-dax: "Dax", Helvetica, Arial, sans-serif;
	$font-clarendon: "Clarendon", Georgia, serif;
	$font-body: "SourceSansPro", Helvetica, Arial, sans-serif;


/* ===================
		Colour Variations 
   =================== */

	$color-white: #fff;

	$color-variance: 10%;

	$color-brand-primary-light: lighten($color-brand-primary, $color-variance);
	$color-brand-primary-dark: darken($color-brand-primary, 5%);

	$color-brand-secondary-light: lighten($color-brand-secondary, $color-variance);
	$color-brand-secondary-dark: darken($color-brand-secondary, $color-variance);

	$color-brand-tertiary-light: lighten($color-brand-tertiary, $color-variance);
	$color-brand-tertiary-dark: darken($color-brand-tertiary, $color-variance);

	$color-success-light: lighten($color-success, $color-variance);
	$color-success-dark: darken($color-success, $color-variance);

	$color-warning-light: lighten($color-warning, $color-variance);
	$color-warning-dark: darken($color-warning, $color-variance);

	$color-info-light: lighten($color-info, $color-variance);
	$color-info-dark: darken($color-info, $color-variance);

	$color-action-primary-light: lighten($color-action-primary, $color-variance);
	$color-action-primary-dark: darken($color-action-primary, $color-variance);

	$color-action-secondary-dark: darken($color-action-secondary, $color-variance);



	//$color-brand-primary: #6F2C91;*/
/*
	//$color-brand-primary-gradient: #590E73;

	//$color-brand-secondary: #F89F1B;*/
/*
	//$color-brand-tertiary: #FDD407;


	$color-action-primary: #F8641B;


	// Feedback
	$color-success: #2C913D;
	$color-warning: #CC0000;
	$color-info: #1E6BB8;

	$color-success-light: lighten($color-success, $color-variance);
	$color-success-dark: darken($color-success, $color-variance);

	$color-warning-light: lighten($color-warning, $color-variance);
	$color-warning-dark: darken($color-warning, $color-variance);

	$color-info-bg: lighten($color-info, 50%);
	$color-info-light: lighten($color-info, $color-variance);
	$color-info-dark: darken($color-info, $color-variance);

	
	// Monochrome
	$color-white: #fff;
	$color-grey-light: #F5F5F5;
	$color-grey: #E5E5E5;
	$color-grey-dark: #C7C7C7;
	$color-black: #000;
	$color-text: #333;
	$color-bg-dark: #2F2F2F;
	$color-white: $color-bg-light;
*/

/* ====================
		TrustPilot Colours
	 ==================== */
	
	$rating-5stars: #007F4E; // Rating over 9.0
	$rating-4stars: #73B143; // Rating between 7.0 and 8.9
	$rating-3stars: #F8CC18; // Rating between 5.0 and 6.9
	$rating-2stars: #F47324; // Rating between 3.0 and 4.9
	$rating-1stars: #E22027; // Rating below 2.9
	$rating-bg: #C8C8C8;


/* ===========
	Animation
   =========== */

	$anim-speed: 0.3s;


/* ======
	Misc
   ====== */

	$shadow: 1px 1px 3px #000;
	
	$padding-input: 0.5em;

	$maxwidth: 70rem;
	$maxwidth-form: 27.5rem;
	$maxwidth-date: 10.65rem;
	$maxwidth-datepicker: 8rem;

	$width-sidebar: 13.5em;
	$width-postsidebar: 14.5em;

	//$radius: 0.1875em;
	$radius: 3px;

