@function set-text-color($color) {
  @if (lightness($color) > 50) {
    @return #000000; // Lighter backgorund, return dark color
  } @else {
    @return #ffffff; // Darker background, return light color
  }
}


  
  $color-brand-primary: #6F2C91;
  .color-brand-primary {
    background: #6F2C91;
    color: set-text-color(#6F2C91);
  }
  
  $color-brand-secondary: #F89F1B;
  .color-brand-secondary {
    background: #F89F1B;
    color: set-text-color(#F89F1B);
  }
  
  $color-brand-tertiary: #FDD407;
  .color-brand-tertiary {
    background: #FDD407;
    color: set-text-color(#FDD407);
  }
  
  $color-action-primary: #F8641B;
  .color-action-primary {
    background: #F8641B;
    color: set-text-color(#F8641B);
  }
  
  $color-action-secondary: #6F2C91;
  .color-action-secondary {
    background: #6F2C91;
    color: set-text-color(#6F2C91);
  }
  

  
  $color-text: #333333;
  .color-text {
    background: #333333;
    color: set-text-color(#333333);
  }
  
  $color-grey: #E5E5E5;
  .color-grey {
    background: #E5E5E5;
    color: set-text-color(#E5E5E5);
  }
  
  $color-grey-light: #F5F5F5;
  .color-grey-light {
    background: #F5F5F5;
    color: set-text-color(#F5F5F5);
  }
  
  $color-grey-dark: #C7C7C7;
  .color-grey-dark {
    background: #C7C7C7;
    color: set-text-color(#C7C7C7);
  }
  

  
  $color-link: #6F2C91;
  .color-link {
    background: #6F2C91;
    color: set-text-color(#6F2C91);
  }
  
  $color-link-hover: #8d38b8;
  .color-link-hover {
    background: #8d38b8;
    color: set-text-color(#8d38b8);
  }
  
  $color-link-active: #60267d;
  .color-link-active {
    background: #60267d;
    color: set-text-color(#60267d);
  }
  

  
  $color-bg-light: #ffffff;
  .color-bg-light {
    background: #ffffff;
    color: set-text-color(#ffffff);
  }
  
  $color-bg-dark: #2F2F2F;
  .color-bg-dark {
    background: #2F2F2F;
    color: set-text-color(#2F2F2F);
  }
  
  $color-bg-banner: #60267d;
  .color-bg-banner {
    background: #60267d;
    color: set-text-color(#60267d);
  }
  
  $color-bg-sidebar: #E8DEEC;
  .color-bg-sidebar {
    background: #E8DEEC;
    color: set-text-color(#E8DEEC);
  }
  

  
  $color-success: #2C913D;
  .color-success {
    background: #2C913D;
    color: set-text-color(#2C913D);
  }
  
  $color-bg-success: #E9F4EB;
  .color-bg-success {
    background: #E9F4EB;
    color: set-text-color(#E9F4EB);
  }
  
  $color-error: #CC0000;
  .color-error {
    background: #CC0000;
    color: set-text-color(#CC0000);
  }
  
  $color-bg-error: #F9E5E5;
  .color-bg-error {
    background: #F9E5E5;
    color: set-text-color(#F9E5E5);
  }
  
  $color-warning: #f2db13;
  .color-warning {
    background: #f2db13;
    color: set-text-color(#f2db13);
  }
  
  $color-bg-warning: #fff9ce;
  .color-bg-warning {
    background: #fff9ce;
    color: set-text-color(#fff9ce);
  }
  
  $color-info: #1E6BB8;
  .color-info {
    background: #1E6BB8;
    color: set-text-color(#1E6BB8);
  }
  
  $color-bg-info: #E8F0F7;
  .color-bg-info {
    background: #E8F0F7;
    color: set-text-color(#E8F0F7);
  }
  


@import 'vendor/normalize';

@import 'base/variables';
@import 'base/base';
@import 'base/typography';
@import 'base/forms';
@import 'base/feedback';
@import 'base/choreography';

@import 'base/syntax-highlighting';
@import 'layout/styleguide';

@import 'components/masthead';
@import 'components/navigation';
@import 'components/breadcrumb';
@import 'components/banners';
@import 'components/asides';
@import 'components/buttons';
@import 'components/mediaobject';
@import 'components/progress';
@import 'components/tables';

@import 'components/sidebar';
@import 'components/contentgrid';
@import 'components/articles';
@import 'components/contenthub';

@import 'components/footer';

@import 'utils/helpers';