.content .aside {
  clear: both;
  float: right;  
  margin-bottom: 1em;
  max-width: 37.5em;

  &--review {
  }
}

.sidebar .aside {
  max-width: 100%;
  width: 100%;
}

.aside {
  background-color: $color-brand-primary-dark;
  color: $color-white;
  overflow: hidden;
  padding: 1.5em;
  position: relative;
  text-shadow: $shadow;
  width: 100%;

    a {
      color: $color-white;
    }

  &--review {
    background: $color-white;
    border: 1px solid $color-grey;
    color: $color-text;
    padding: 1em;
    text-shadow: none;

    header {
      overflow: hidden;
    }

    .aside__title {
      clear: left;
      margin-top: 0.75em;
    }

    a {
      color: $color-link;

      &:hover {
        color: $color-link-hover;
      }

      &:active {
        color: $color-link-active;
      }
    }
  }

  &--sidebar {
    background: $color-bg-sidebar;
    color: $color-text;
    padding: 0;
    text-shadow: none;

    .aside__title {
      background-color: $color-brand-primary-dark;
      color: $color-white;
      font-weight: normal;
      font-size: 1.125em;
      padding: 0.5em 1em;
      text-shadow: $shadow;

      display: flex;
      align-items: center;
      //justify-content: center;

      img {
        float: left;
        margin-right: 1em;
      }
    }

    .aside__content {
      margin-top: 0;
      padding: 0em 1em 1em;
    }
  }
}

.aside__title {
  font-family: $font-body;
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 0;
}




/* =================
    Customer Review
   ================= */

    .review__logo {
      //float: right;
      display: block;
      margin: 0 auto;
      width: 10em;
    }

    .review__rating {
      display: block;
      margin: 1em auto 0;
      //margin-top: 0;
      max-width: 18.75em;
      overflow: hidden;
      //width: 100%;
      width: 10em;

      img {
        height: 1.3036875em;
        width: 1.359375em;
      }

      &--5star li:nth-child(-n+5){
        background: $rating-5stars;
      }

      &--4star li:nth-child(-n+4){
        background: $rating-4stars;
      }

      &--3star li:nth-child(-n+3){
        background: $rating-3stars;
      }

      &--2star li:nth-child(-n+2){
        background: $rating-2stars;
      }

      &--1star li:nth-child(-n+1){
        background: $rating-1stars;
      }
    }

    .review__rating li {

      background: $rating-bg;
      border-radius: $radius;
      float: left;
      margin-right: 2px;
      padding: 0.25em 0.25em 0;
      max-width: 2em;
      width: calc(20% - 2.25px);

      &:last-child {
        margin-right: none;
      }

    }

    @media screen and (min-width: 30em){

      .review__logo {
        float: left;
        margin: 0.5em 0;
      }

      .review__rating {
        float: right;
        margin-top: 0;
      }
    }

    @media screen and (min-width: 37.5em){

      .content .aside--review {
        max-width: 18.75em;
      }

      .review__logo {
        float: none;
        margin: 0 auto;
      }

      .review__rating {
        float: none;
        margin: 1em auto 0;
      }

      .aside--review .aside__title {
        margin-top: 0.75em;    
      }

    }
    
/* ==============
    Footer Aside
   ============== */

    footer .aside {
      border-top: 1px solid rgba($color-white, 0.25);
    }

@media screen and (min-width: 37.5em) {

  .aside {
    max-width: 30em;
    width: 50%;
  }

  .content .aside {
    margin: 1em 0 1em 1em;
  }

  footer .aside {
    float: left;
    margin: 0;
    max-width: none;

    &:nth-of-type(2n-1){
      border-right: 1px solid rgba($color-white, 0.25);
    }
  }

}

@media screen and (min-width: 50em) and (max-width: 53.75em){
  .sidebar + .content .aside {
    width: 100%;
  }
}

.list__social {

  list-style-type: none;
  margin-top: 0;
  padding: 0;

  li {

    display: inline-block;
    margin-right: 1em;
    margin-top: 1em;

    &:last-of-type {
      margin-right: 0;
    }

  }

}