

/* ====================
    Sidebar Navigation 
   ==================== */

  .sidebar {
    float: left;
    width: 100%;

    &--right {
      float: right;
    }

    img {
      display: block;
      margin: 0;
    }

    .sidebar__list:first-child {
      margin-top: 0;
    }
  }

  .sidebar__list {
    background: $color-grey-light;
    clear: left;
    list-style-type: none;
    padding: 0 0 0.1em;
  }

  .sidebar__header {
    background: $color-grey;
    line-height: 1.25em;
    margin-bottom: 0.5em;
    padding: 0.5em 0.5em;
  }

  .sidebar__item {
    line-height: 1.1em;
    margin-bottom: 0.5em;
  }

  .sidebar__link {
    display: block;
    padding: 0.125em 0.5em;

    &--viewall {
      padding: 0.125rem 0.5rem;
      font-size: 0.75em;
      text-align: right;
      text-transform: uppercase;
    }
  }

  .sidebar__item + .sidebar__header {
    margin-top: 0.5em;
  }

  .sidebar__item:last-child {
    margin-bottom: 0.5em;
  }

  @media screen and (min-width: 50em){
    .sidebar {
      margin-bottom: 1em;
      margin-right: 1em;
      width: $width-sidebar;
    }
  }

  @media screen and (min-width: 57em){
    .sidebar {
      width: 16em;
    }
  }

  @media screen and (min-width: 64em){
    .sidebar {
      width: 19em;
    }
  }


/* Sidebar Search */

  .search {
    background: $color-grey-light;
    margin-bottom: 1em;

    .sidebar__header {
      margin-bottom: 0;
      margin-top: 0;
    }
  }


/* Sidebar With Content */
  

  .sidebar ~ .content {
    clear: none;
    float: left;
  }

  @media screen and (max-width: 37.5em) {

    .sidebar ~ .content,
    .sidebar ~ .contentgrid {
      float: left;
    }

  }



  @media screen and (min-width: 50em){
    .sidebar ~ .content,
    .sidebar ~ .contentgrid {
      width: calc(100% - #{$width-postsidebar});
    }
    .sidebar + .contentgrid + .content {
      margin-left: #{$width-postsidebar};
    }
  }

  @media screen and (min-width: 57em){
    .sidebar ~ .content,
    .sidebar ~ .contentgrid {
      width: calc(100% - 17em);
    }
    .sidebar + .contentgrid + .content {
      margin-left: 17em;
    }
  }

  @media screen and (min-width: 64em){
    .sidebar ~ .content,
    .sidebar ~ .contentgrid {
      width: calc(100% - 20em);
    }
    .sidebar + .contentgrid + .content {
      margin-left: 20em;
    }
  }

  @media screen and (min-width: 57em){

    .sidebar--right {
    }

  }

/* ================
    Single Sidebar 
   ================ */

  @media screen and (min-width: 50em){
    .singlesidebar {
      .sidebar {
        margin: 0;
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 57em){

    .singlesidebar {
      .sidebar {
        width: $width-sidebar;

        &--right {
          margin-left: 1em;
          margin-right: 0;
          margin-top: 0;
        }

      }

      .content {
        width: calc(100% - #{$width-postsidebar});
      }

    }
    
  }

  @media screen and (min-width: 70em){

    .singlesidebar {
      .sidebar {
        width: 17em;
      }
      .content {
        width: calc(100% - 18em);
      }
    }
    
  }


  
/* ===============
    Dual Sidebars
   =============== */

  @media screen and (min-width: 50em){

    .dualsidebars {
      .sidebar--right {
        margin-left: #{$width-postsidebar};
        margin-right: 0;
        margin-top: 0;
        width: calc(100% - #{$width-postsidebar});
      }
    }

  }

  @media screen and (min-width: 57em){

    .dualsidebars {
      .sidebar--left {
        width: $width-sidebar;
      }
      .content {
        width: calc(100% - #{$width-postsidebar});
      }
    }
    
  }

  @media screen and (min-width: 64em){

    .dualsidebars {
      .sidebar--left {
        width: $width-sidebar;
      }
      .sidebar--right {
        margin-left: 1em;
        margin-right: 0;
        margin-top: 1em;
        width: $width-sidebar;
      }
      .content {
        width: calc(100% - #{$width-postsidebar} - #{$width-postsidebar});
      }
    }
    
  }

  @media screen and (min-width: 70em){

    .dualsidebars {
      .sidebar--right {
        width: 17em;
      }
      .content {
        width: calc(100% - #{$width-postsidebar} - 18em);
      }
    }
    
  }

  .trustpilot-widget {
    border: 1px solid $color-grey;
    padding: 1.5em 1.5em 0.75em;
    text-align: center;

    &:first-child {
      margin-top: 0;
    }
  }

