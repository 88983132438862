/* ===========================
	Set Form Styling Defaults
   =========================== */

	form {
		//margin-top: 0;
	}

	h2 + fieldset {
		margin-top: 0.5em;
	}

	fieldset {
		border: none;
		margin: 0;
		padding: 0;

		.list--unstyled {
			margin-top: 0.5em;
		}
	}

	fieldset,
	fieldset > p {
		clear: both;
		float: left;
		width: 100%;
	}

	.fieldwrapper {
		clear: left;
		float: left;
		width: 100%;
	}

  .inputwrapper{
    margin: 0;
  }

	label {
		display: block;

		&:first-of-type {
			margin-top: 0;
		}

		span {
			display: block;
		}

		input + span {
			margin-left: 2em;
			margin-top: 0.15em;
		}

	}

	:disabled {
  	background: $color-grey-light;  
	}

	input,
	.customselect select,
	textarea {
		-moz-appearance: none;
		-webkit-appearance: none;
		background: #FFFFFF;
		border: none;
    border-radius: 0;
		clear: left;
		display: block;
		float: left;
		//margin-right: 1em;
		padding: $padding-input;
		width: 100%;
	}

	input:not(.btn),
	.customselect,
	textarea {
		border: 1px solid $color-grey-dark;
    float: left;
		margin-bottom: 0;
		margin-top: 0.25em;
		max-width: $maxwidth-form;

		&:focus,
		&.focus {
			border: 1px solid $color-info;
		}

	}

	input[type=checkbox],
	input[type=radio] {
		height: 1em;
		margin-left: 0.5em;
		margin-right: 0.5em;
		max-width: 1em;
		width: 1em;
		//-moz-appearance: checkbox;
		//-webkit-appearance: checkbox;
	}

	input[type=checkbox]:checked {
		background-image: url(../assets/icons/input-valid.svg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 1.5em;
		border-color: $color-success;
	}

	input[type=radio]:checked {
		background-color: $color-success;
		border-color: $color-success;
		box-shadow: inset 0 0 0 2px #fff;
	}

	select:focus::-ms-value {
		background: $color-info;
	}

	.customselect  {
		display: inline-block;
		position: relative;
		width: 100%;
		z-index: 1;

		&:after {
			background: $color-white;
			color: #4a4a4a;
			content: "▼";
			font-size: 60%;
			height: 90%;
			line-height: 3.5em;
			padding: 0 15px;
			pointer-events: none;
			position: absolute;
			right: 1px;
			top: 1px;
			z-index: 5;
		}

		&--autocomplete {
			border: none;
		}

		select::-ms-expand {
			display: none;
		}

	}

	.js span.customselect--autocomplete {
		border: none;
		margin: 0;
		padding: 0;
		position: static;
		overflow: visible;
		width: 100%;

		@media screen and (min-width: 37.5em) {
			width: 60%;
		}

		&:after {
			background: transparent;
			content: "";
		}

	}

	option {
		margin: 0;
	}

	input.input--date {
		max-width: $maxwidth-date;
	}

	input[type=text].input--monetary {
		background-image: url(../assets/icons/icon-pound.svg);
		background-position: 0.5em center;
		background-repeat: no-repeat;
		//background-size: 9px 18px;
		padding-left: 1.25em;
	}

	input[type=radio] {
		border-radius: 100%;
	}

	input:focus,
	select:focus,
	textarea:focus {
		//outline: none;
	}

	input:focus,
	.customselect--focus,
	textarea:focus {
		//border: 1px solid $color-info !important;
		//outline: none;
	}

/* =================
	Question Layout
   ================= */

	.question__container,
	.question {
		margin-top: 1em;
	}

	.question__container,
	.question,
	.fieldwrapper {
		clear: left;
		float: left;
		position: relative;
		width: 100%;
	}

	.question__heading {
		padding: 0.5em 0 0;
		position: relative;

		@media screen and (min-width: 37.5em){
			position: static;
			//padding: 0.9375em 0 0;
			//padding: 1.1em 0 0;
		}

		+ span {
			padding: 0;
		}

		&.customselect {
			padding: 0;
		}

	}

	@media screen and (min-width: 37.5em){
		legend.question__heading {
			padding-top: 0.75em;
		}
		span.question__heading {
			padding-top: 1.2em;
		}
	}

	.question__isoptional {
		color: $color-grey-dark;
		font-size: 0.8em;
		margin-top: 0;
		position: absolute;
		right: 0.35em;
		bottom: -2em;

		@media screen and (min-width: 37.5em){
			top: 0.4em;
		}
	}

	.question__subset {
		border-bottom: 1px solid $color-grey;
		border-top: 1px solid $color-grey;
		box-shadow: inset 0 5px 7px -5px rgba(0,0,0,0.2), inset 0 -5px 7px -5px rgba(0,0,0,0.2);
		clear: left;
		display: none;
		float: left;
		margin-left: -1em;
		margin-right: -1em;
		padding: 0 1em 1em;
		position: relative;
		width: calc(100% + 2em);

		&:after {
			border: 1em solid black;
			border-color: transparent transparent $color-white $color-white;
			box-shadow: -3px 3px 5px 0 rgba(0, 0, 0, 0.125);
			content: "";
			height: 0;
			left: 50%;
			margin-left: -1.5em;
			position: absolute;
			top: -2px;
			transform-origin: 0 0;
			transform: rotate(-45deg);
			width: 0;
		}
	}

	.subset__container {
		clear: left;
		float: left;
		margin-top: 2em;
		width: 100%;
	}

	.subset__heading {
		margin-bottom: 0;
		text-align: center;
	}

	.input {

		&--small {
			width: 10em;
			/*max-width: 10em;
			width: 45%;*/
		}

		&--error,
		&--valid {
			background-position: center right;
			background-repeat: no-repeat;
			background-size: auto 100%;
			padding-right: 35px;
		}

		&--error {
			background-image: url("../assets/icons/input-error.svg");
			border: 1px solid $color-error;
		}

		&--valid {
			background-image: url("../assets/icons/input-valid.svg");
			border: 1px solid $color-success;
		}
	}

/* ==================
	Question Options
   ================== */

	.question__options {
		margin-bottom: 0em;
		margin-top: 0;
	}

/* ===========================
	Question Options - Base
   =========================== */
	
	.question__option {
		border: 1px solid $color-grey-dark;
		display: inline-block;
		margin-bottom: 0;
		margin-top: 0.25em;
		//margin-top: 0;
		width: 4em;
		text-align: center;

		input {
			margin: 0 auto;
		}

		label {
			cursor: pointer;
			padding: $padding-input;
		}

		span {
			display: block;
			margin-top: 0.5em;
			margin-bottom: 0.25em;
		}

		&:hover {
			border: 1px solid $color-info;
		}

	}

	/* JS enhanced styling */

		.js .question__option {

			input[type=radio] {
				position: absolute; 
				overflow: hidden; 
				clip: rect(0 0 0 0); 
				height: 1px; width: 1px; 
				margin: -1px; padding: 0; border: 0; 
			}

			span {
				margin-top: 0.25em;
			}
		}

		.question__option--selected {
			background: $color-success;
			border: 1px solid $color-success;
			color: #fff;

			&:hover {
				color: $color-white;
			}
		}
/*
		.js .question__option--focus {
			border: 1px solid $color-info !important;
			outline: none;
		}
*/

/* ==================================
	Question Options - Cover & Locks
   ================================== */
	
	.question__heading--cover,
	.question__heading--lock {
		float: left;
		text-align: left;
	}
	
	.question__container--cover,
	.question__container--lock {
		margin-top: 0;
	}
	
	.question__option--cover,
	.question__option--lock {
		border: 1px solid $color-grey-dark;
		border-radius: $radius;
		float: left;
		margin-top: 0.5em;
		text-align: left;
		width: 100%;

		label {
			margin: 0;
			overflow: hidden;
			padding: $padding-input;

			span {
				margin-left: 0.5em;
			}

		}

	}	

	.icon__cover,
	.icon__lock {
		display: none;
	}

	
	@media screen and (min-width: 20em) {
	
		.question__option--cover label span,
		.question__option--lock label span {
			display: block;
			margin-top: 0.75em;
			margin-bottom: 0;
			margin-left: 0;
			text-align: left;
		}

		.icon__cover, 
		.icon__lock {
			display: inline;
			float: left;
			margin-bottom: 0;
			margin-right: 1em;
			margin-top: 0;
			width: 50px;
		}

		.icon__lock {
			width: 100px;
		}

	}
	
	@media screen and (min-width: 25em) {

		.question__container--cover,
		.question__container--lock {
			clear: none;
			float: left;
			width: 100%;
		}

		.question__option--cover,
		.question__option--lock {
			display: inline-block;
			margin-right: 0.5em;
			text-align: center;
			width: calc(33.333333333% - 0.333333333em);
			width: 31.6% \9;

			&:nth-child(3n) {
				margin-right: 0;
			}

			label {
				height: 135px;
				width: 100%;

				span {
					text-align: center;
				}
			}

			input[type=radio] {
				clear: both;
				display: block;
				margin: 0 auto;
			}
		}

		.question__option--lock label {
			height: 240px;
		}

		img.icon__cover,
		img.icon__lock {
			display: block;
			float: none;
			margin: 0.5em auto 1em;
			max-width: 60px;
			width: 100%;
		}

		img.icon__lock {
			max-width: 100px;
			width: 100%;
		}

	}

	// IE11 correction for cover options
	@media screen and (min-width: 25em) and (-ms-high-contrast:none) {
		.question__option--cover,
		.question__option--lock {
			width: 31.6%
		}
	}

	@media screen and (min-width: 37.5em) {

		.question__container--cover,
		.question__container--lock {
			width: 60%;
		}

	}

	/* JS enhanced styling */

		.js .question__option {

			input[type=radio] {
				position: absolute; 
				overflow: hidden; 
				clip: rect(0 0 0 0); 
				height: 1px; width: 1px; 
				margin: -1px; padding: 0; border: 0; 
			}

			span {
				margin-top: 0.25em;
			}
		}

		.js .question__option--cover span,
		.js .question__option--lock span {
			margin-top: 0em;
		}

		.question__option--selected {
			background: $color-success;
			border: 1px solid $color-success;
			color: #fff;

			svg path {
				color: white;
			}
		}

		/*.js .question__option--focus {
			border: 1px solid $color-info !important;
			outline: none;
		}*/

		@media screen and (min-width: 20em) {

			.js .question__option--cover span,
			.js .question__option--lock span {
				margin-top: 0.75em;
			}

		}

/* ================================
	Question - Large screen layout
   ================================ */

	@media screen and (min-width: 37.5em) {

		.question__heading {
			padding-right: 1em;
			text-align: right;
		}
	
		.question__heading--cover {
		}

		.customselect {
			line-height: 1.25em;

			&:after {
				//right: 1px;
			}

		}

		.question__heading,
		.question__container > legend,
		fieldset.question__container label {
			float: left;
			width: 40%;
		}

		fieldset.question__container .question {
			clear: left;
			margin-top: 0;
			width: 100%;
		}

		.question__answer,
		.question__container .question,
		fieldset.question__container .question__options {
			clear: none;
			width: 60%;
		}

		.question__answer {
			display: inline-block;
			margin-top: 1em;

			&--static {
				margin-top: 1.25em;
			}
		}

		fieldset.question__container .question__options label {
			width: 100%;
		}

		input,
		.textarea,
		span.customselect {
			clear: none;
			//float: right;
			margin-right: 0;
			//overflow: hidden;
			width: 100%;
		}

		/*.input--small {
			width: 30%;
		}*/

		textarea {
			height: 10em;
		}

	}

/* Sidebar Forms */

	.sidebar {
		.question__heading,
		.question__container > legend,
		fieldset.question__container label,
		.question__answer,
		.question__container .question,
		fieldset.question__container .question__options {
			clear: both;
			padding-top: 0;
			text-align: left;
			width: 100%;
		}

		form .question:first-child {
			margin-top: 0;
		}

		.formcontrols {
			margin: 0 0 1em;
		}
	}


/* ==========
	Guidance
   ========== */

	.guidance {
		background: $color-grey;
		border: 1px solid $color-grey-dark;
    border-top: none;
		clear: left;
		float: left;
		margin-top: -1px;
		margin-top: 0;
		max-width: $maxwidth-form;
		width: 100%;

		ul {
			margin: 0 0 0 2em;
			padding: 0;
		}

		p, li {
			font-size: 0.8em;
			line-height: 1.35em;
			margin: 0;
			padding: 0 0.5em 0.5em;
		}

		p:first-of-type {
			margin-top: 0.5em;
		}

		li {
			padding-top: 0;
		}

	}

	input:focus ~ .guidance,
	.customselect--focus ~ .guidance,
	textarea:focus ~ .guidance,
	.guidance--highlight {
		background: $color-bg-info;
		border: 1px solid $color-info;
		border-top: none;
		color: $color-info;

    box-shadow: 0 0 10px $color-info;
    outline: none;
    z-index: 99;
	}

	.question__container--cover .guidance,
	.question__container--multiple .guidance {
		clear: both;
		margin-top: 0.5em;
		width: 100%;
	}

	.js .guidance--ancillary p {
		margin: 0.5em 0 0;
	}

	.guidance__trigger {
		cursor: pointer;
		display: inline-block;
		margin: 0.35em 0 0 0.45em;

		img {
			width: 2.2em;
		}

		~ input {
    	//max-width: $maxwidth-datepicker;
    	max-width: calc(#{$maxwidth-form} - 2.65em);
			width: calc(100% - 2.65em);
		}
	}

	@media screen and (min-width: 37.5em) {

		/*.guidance {
			clear: none;
			float: right;
			width: 60%;
		}

		.question__container--multiple .guidance {
			margin-top: 0.25em;
		}*/

	}
	/*.question__container--multiple .guidance {
		margin-top: 0;
	}*/
	
	/*
	.guidance__icon {
		background: url(../assets/icons/icon-info.svg);
	    display: inline-block;
	    float: right;
	    height: 20px;
	    margin: 0.5em 0.5em;
	    width: 20px;
	}

	.guidance__content {
		background-color: lighten($color-info,54%);
		border: 1px solid $color-info;
		border-radius: $radius;
		clear: left;
		color: $color-info;
		display: block;
		display: none;
	    float: left;
		margin-top: 0.5em;
		padding: 0 1em;
		position: relative;
		font-size: 0.875em;
		width: 100%;

		&:before,
		&:after {
			border-left: 0.6em solid transparent;
			border-right: 0.6em solid transparent;
			content: '';
			height: 0;
			position: absolute;
			right: 0.7em;
			top: -1em;
			width: 0; 
			border-bottom: 1em solid $color-info;
		}

		&:after {
			border-bottom: 1em solid lighten($color-info,54%);
			top: -0.85em;
		}

		p {
			margin: 0.8125em 0;
		}
	}
*/

/* ==============
	Find Address
   ============== */

	.findaddress legend {
		float: left;
		font-weight: bold;
		margin-bottom: -1em;
		width: 60%;
	}

	@media screen and (min-width: 37.5em) {
		.findaddress legend {
			float: left;
			font-weight: bold;
			margin-left: 40%;
			margin-bottom: -1em;
			width: 60%;
		}
	}

/* ====================
	Autocomplete Field
   ==================== */

	.chosen-container {
		background: $color-white;
		border: 1px solid $color-grey-dark;
		clear: none;
		display: block;
		margin-bottom: 0;
		//margin-top: 0.25em;
		margin-top: 0;
		margin-right: 1em;
		position: relative;
		vertical-align: middle;
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
		width: 100% !important;

		a:hover,
		a:active {
			color: $color-text;
		}

		&:after {
			background: $color-white;
			color: #4a4a4a;
			content: "▼";
			font-size: 60%;
			height: 90%;
			line-height: 3.5em;
			padding: 0 15px;
			pointer-events: none;
			position: absolute;
			right: 1px;
			top: 1px;
			z-index: 5;
		}

		@media screen and (min-width: 37.5em) {
			clear: none;
			float: right;
			margin-right: 0;
			//width: 60% !important;
		}
	}

	.chosen-container-active,
	.chosen-container-active.chosen-with-drop {
		border: 1px solid $color-info;

    box-shadow: 0 0 10px $color-info;
    outline: none;
    z-index: 99;
	}

	.chosen-drop {
		position: absolute;
		top: 100%;
		left: -9999px;
		z-index: 1010;
		//width: 100%;
		width: calc(100% + 2px);
		border: 1px solid $color-info;
		border-top: 0;
		background: $color-white;
	}

	.chosen-with-drop .chosen-drop {
		left: -1px;
	}

	.chosen-container a {
		cursor: pointer;
	}

	.chosen-single {
		background: $color-white;
		background-clip: padding-box;
		//border: 1px solid $color-grey-dark;
		display: block;
		height: auto;
		margin-right: 1em;
		padding: $padding-input;
		position: relative;
		overflow: hidden;
		text-decoration: none;
		white-space: nowrap;
		width: 100%;

	}
	.chosen-default {
		color: #999;
	}

	.chosen-single span {
		display: block;
		overflow: hidden;
		margin-right: 26px;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.chosen-single-with-deselect span {
		margin-right: 38px;
	}

	.chosen-single abbr {
		position: absolute;
		top: 6px;
		right: 26px;
		display: block;
		width: 12px;
		height: 12px;
		font-size: 1px;
	}

	.chosen-single abbr:hover {
		background-position: -42px -10px;
	}

	.chosen-disabled .chosen-single abbr:hover {
		background-position: -42px -10px;
	}

	.chosen-single div {
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		width: 18px;
		height: 100%;
	}

	.chosen-single div b {
		display: block;
		width: 100%;
		height: 100%;
	}

	.chosen-search {
		position: relative;
		z-index: 1010;
		margin: 0;
		padding: 3px 4px;
		white-space: nowrap;
	}

	.chosen-search input[type="text"] {
		margin: 1px 0;
		padding: 4px 20px 4px 5px;
		width: 100%;
		height: auto;
		outline: 0;
		border: 1px solid $color-grey;
		font-size: 1em;
		font-family: sans-serif;
		line-height: normal;
		border-radius: 0;

    box-shadow: none;
    //outline: none;
    //z-index: 99;
	}

	.chosen-drop {
		margin-top: -1px;
		background-clip: padding-box;
	}

	.chosen-container-single-nosearch .chosen-search {
		position: absolute;
		left: -9999px;
	}

	.chosen-results {
		color: #444;
		position: relative;
		overflow-x: hidden;
		overflow-y: auto;
		max-height: 240px;

		margin: 0 0 4px 0;
		padding: 0;
		-webkit-overflow-scrolling: touch;
		width: 100%;

		li {
			display: none;
			margin: 0;
			padding: 5px 6px;
			list-style: none;
			line-height: 15px;
			word-wrap: break-word;
			-webkit-touch-callout: none;
		}

		li.active-result {
			display: list-item;
			cursor: pointer;
		}

		li.disabled-result {
			display: list-item;
			color: $color-grey-dark;
			cursor: default;
		}

		li.highlighted {
			background-color: $color-info;
			color: $color-white;
		}

		li.no-results {
			background: $color-grey-light;
			color: $color-text;
			display: list-item;
			line-height: 1.2em;

			span {
				display: inline;
			}
		}

		li.group-result {
			display: list-item;
			font-weight: bold;
			cursor: default;
		}

		li.group-option {
			padding-left: 15px;
		}

		li em {
			font-style: normal;
			text-decoration: underline;
		}
	}


/* ===============
	Date Picker 
   =============== */

  .js .datepicker {
    max-width: $maxwidth-datepicker;
    width: calc(100% - 2.65em);
  }

	.pika-single {
	    z-index: 9999;
	    display: block;
	    position: relative;
	    color: $color-text;
	    background: $color-white;
	    border: 1px solid $color-info;
	    margin-bottom: 1em;
	    //border-bottom-color: #bbb;
	    //font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	}

	/*
	clear child float (pika-lendar), using the famous micro clearfix hack
	http://nicolasgallagher.com/micro-clearfix-hack/
	*/
	.pika-single:before,
	.pika-single:after {
	    content: " ";
	    display: table;
	}
	.pika-single:after { clear: both }
	.pika-single { *zoom: 1 }

	.pika-single.is-hidden {
	    display: none;
	}

	.pika-single.is-bound {
	    position: absolute;
	    box-shadow: 0 5px 15px -5px rgba(0,0,0,.5);
	}

	.pika-lendar {
	    float: left;
	    width: 240px;
	    margin: 8px;
	}

	.pika-title {
	    position: relative;
	    text-align: center;
	}

	.pika-label {
	    display: inline-block;
	    *display: inline;
	    position: relative;
	    z-index: 9999;
	    overflow: hidden;
	    margin: 0;
	    padding: 5px 3px;
	    font-size: 14px;
	    line-height: 20px;
	    font-weight: bold;
	    background-color: $color-white;
	}
	.pika-title select {
	    cursor: pointer;
	    position: absolute;
	    z-index: 9998;
	    margin: 0;
	    left: 0;
	    top: 5px;
	    filter: alpha(opacity=0);
	    opacity: 0;
	}

	.pika-prev,
	.pika-next {
	    display: block;
	    cursor: pointer;
	    position: relative;
	    outline: none;
	    border: 0;
	    padding: 0;
	    width: 20px;
	    height: 30px;
	    /* hide text using text-indent trick, using width value (it's enough) */
	    text-indent: 20px;
	    white-space: nowrap;
	    overflow: hidden;
	    background-color: transparent;
	    background-position: center center;
	    background-repeat: no-repeat;
	    background-size: 75% 75%;
	    opacity: .5;
	    *position: absolute;
	    *top: 0;
	}

	.pika-prev:hover,
	.pika-next:hover {
	    opacity: 1;
	}

	.pika-prev,
	.is-rtl .pika-next {
	    float: left;
	    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==');
	    *left: 0;
	}

	.pika-next,
	.is-rtl .pika-prev {
	    float: right;
	    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=');
	    *right: 0;
	}

	.pika-prev.is-disabled,
	.pika-next.is-disabled {
	    cursor: default;
	    opacity: .2;
	}

	.pika-select {
	    display: inline-block;
	    *display: inline;
	}

	.pika-table {
	    width: 100%;
	    border-collapse: collapse;
	    border-spacing: 0;
	    border: 0;
	}

	.pika-table th,
	.pika-table td {
	    width: 14.285714285714286%;
	    padding: 0;
	}

	.pika-table th {
	    color: $color-text;
	    font-size: 12px;
	    line-height: 25px;
	    font-weight: bold;
	    text-align: center;
	}

	.pika-button {
	    cursor: pointer;
	    display: block;
	    box-sizing: border-box;
	    -moz-box-sizing: border-box;
	    outline: none;
	    border: 0;
	    margin: 0;
	    width: 100%;
	    padding: 5px;
	    color: $color-text;
	    font-size: 12px;
	    line-height: 15px;
	    text-align: right;
	    background: $color-grey-light;
	}

	.pika-week {
	    font-size: 11px;
	    color: $color-grey-dark;
	}

	.is-today .pika-button {
	    color: $color-info;
	    font-weight: bold;
	}

	.is-selected .pika-button {
	    color: $color-white;
	    font-weight: bold;
	    background: $color-info;
	    //box-shadow: inset 0 1px 3px #178fe5;
	}

	.is-inrange .pika-button {
	    background: $color-grey-light;
	}

	.is-startrange .pika-button {
	    color: $color-white;
	    background: $color-grey;
	    box-shadow: none;
	    border-radius: 3px;
	}

	.is-endrange .pika-button {
	    color: $color-white;
	    background: $color-grey-light;
	    box-shadow: none;
	    border-radius: 3px;
	}

	.is-disabled .pika-button,
	.is-outside-current-month .pika-button {
	    pointer-events: none;
	    cursor: default;
	    color: $color-grey-dark;
	    opacity: .3;
	}

	.pika-button:hover {
	    color: $color-white;
	    background: $color-brand-secondary-dark;
	    box-shadow: none;
	}

	/* styling for abbr */
	.pika-table abbr {
	    border-bottom: none;
	    cursor: help;
	}


/* ===============
	Form Controls 
   =============== */

	.formcontrols {
		clear: left;
		float: left;
		margin-top: $vertical-rhythm;
		text-align: right;
		width: 100%;

		a.negative {
			color: $color-warning;
			display: inline-block;
			float: left;
			margin-left: 0.5em;
			margin-top: 1.5em;
		}

		.btn--submit {
			font-size: 1.25em;
		}

		&:first-child {
			margin-top: 0;
		}

	}

/* =================
	Newsletter Form
   ================= */

	.form__newsletter,
	.form__search {

		//overflow: hidden;
		margin-bottom: 0;

		input[type=email],
		input[type=text] {
			float: left;
			margin-right: 0.5em;
			width: 100%;
			width: calc(100% - 6em);
		}

		.btn {
			margin-right: 0;
			margin-top: 0.5em;
		}

	}

	.form__search {
		margin-top: 0;
		overflow: hidden;
		padding: 1em;

		.btn {
			margin-top: 0.25em;
		}

	}

	@media screen and (min-width: 26.25em){

		.form__newsletter {
			
			input[type=email] {
				max-width: 20em;
				width: calc(100% - 6em);
			}

			.btn {
				margin-right: 0;
				margin-top: 0.25em;
			}

		}

	}
