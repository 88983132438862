a.btn {
  &:visited,
  &:hover,
  &:active {
    color: $color-white !important;
  }
}

/*p > a.btn {
  margin-top: 0;
}*/

a.btn,
.btn {
  background: $color-action-primary;
  background: -webkit-gradient(linear, left top, left bottom, from($color-action-primary-light), to($color-action-primary-dark));
  background: -webkit-linear-gradient(top, $color-action-primary-light, $color-action-primary-dark);
  background: -moz-linear-gradient(top, $color-action-primary-light, $color-action-primary-dark);
  background: -ms-linear-gradient(top, $color-action-primary-light, $color-action-primary-dark);
  background: -o-linear-gradient(top, $color-action-primary-light, $color-action-primary-dark);
  //border: 1px solid #fff;
  border: none;
  //box-shadow: inset 0px -2px 0px 0px darken($color-action-primary-dark, $color-variance);
  border-radius: 3px;
  color: $color-white !important;
  cursor: pointer;
  display: inline-block;
  //float: left;
  font-family: $font-dax;
  line-height: 1;
  margin: 1rem 0.5em 0 0;
  padding: 0.55em 1em 0.45em;
  text-align: center;
  text-decoration: none !important;
  text-shadow: darken($color-action-primary-dark, $color-variance) 0 -1px 1px;
  //width: 100%;

  &:hover,
  &.btn--hover {
    background: $color-action-primary;
    background: -webkit-gradient(linear, left top, left bottom, from($color-action-primary-dark), to($color-action-primary-light));
    background: -webkit-linear-gradient(top, $color-action-primary-dark, $color-action-primary-light);
    background: -moz-linear-gradient(top, $color-action-primary-dark, $color-action-primary-light);
    background: -ms-linear-gradient(top, $color-action-primary-dark, $color-action-primary-light);
    background: -o-linear-gradient(top, $color-action-primary-dark, $color-action-primary-light);
    //box-shadow: none;
    padding: 0.6em 1em 0.4em;
  }

  &:active,
  &.btn--active {
    background: $color-action-primary;
    background: -webkit-gradient(linear, left top, left bottom, from($color-action-primary-dark), to($color-action-primary-light));
    background: -webkit-linear-gradient(top, $color-action-primary-dark, $color-action-primary-light);
    background: -moz-linear-gradient(top, $color-action-primary-dark, $color-action-primary-light);
    background: -ms-linear-gradient(top, $color-action-primary-dark, $color-action-primary-light);
    background: -o-linear-gradient(top, $color-action-primary-dark, $color-action-primary-light);
    box-shadow: inset 0px 2px 0px 0px darken($color-action-primary-dark, $color-variance);
    padding: 0.7em 1em 0.3em;
  }

  &:disabled {
    background: $color-grey;
    color: $color-text;
    text-shadow: none;
    &:hover {
      padding: 0.55em 1em 0.45em;
    }
  }

  &--large {
  font-size: 1.25em;
  //margin-top: 1.25em;
  padding: 0.7em 1em;
    &:hover,
    &.btn--hover {
      padding: 0.75em 1em 0.65em;
    }

    &:active,
    &.btn--active {
      padding: 0.8em 1em 0.6em;
    }
  }

  &--secondary {
    background: $color-brand-primary;
    background: -webkit-gradient(linear, left top, left bottom, from($color-brand-primary-light), to($color-brand-primary-dark));
    background: -webkit-linear-gradient(top, $color-brand-primary-light, $color-brand-primary-dark);
    background: -moz-linear-gradient(top, $color-brand-primary-light, $color-brand-primary-dark);
    background: -ms-linear-gradient(top, $color-brand-primary-light, $color-brand-primary-dark);
    background: -o-linear-gradient(top, $color-brand-primary-light, $color-brand-primary-dark);
    //box-shadow: inset 0px -2px 0px 0px darken($color-brand-primary-dark, $color-variance);
    text-shadow: 0px -1px 0px $color-brand-primary-dark;

    &:hover,
    &.btn--hover {
      background: -webkit-gradient(linear, left bottom, left top, from($color-brand-primary-light), to($color-brand-primary-dark));
      background: -webkit-linear-gradient(bottom, $color-brand-primary-light, $color-brand-primary-dark);
      background: -moz-linear-gradient(bottom, $color-brand-primary-light, $color-brand-primary-dark);
      background: -ms-linear-gradient(bottom, $color-brand-primary-light, $color-brand-primary-dark);
      background: -o-linear-gradient(bottom, $color-brand-primary-light, $color-brand-primary-dark);
      box-shadow: none;
    }

    &:active,
    &.btn--active {
      background: -webkit-gradient(linear, left bottom, left top, from($color-brand-primary-light), to($color-brand-primary-dark));
      background: -webkit-linear-gradient(bottom, $color-brand-primary-light, $color-brand-primary-dark);
      background: -moz-linear-gradient(bottom, $color-brand-primary-light, $color-brand-primary-dark);
      background: -ms-linear-gradient(bottom, $color-brand-primary-light, $color-brand-primary-dark);
      background: -o-linear-gradient(bottom, $color-brand-primary-light, $color-brand-primary-dark);
      box-shadow: inset 0px 2px 0px 0px darken($color-brand-primary-dark, $color-variance);
    }
/*
    &:active {
      //box-shadow: inset 0px 2px 0px 0px $color-brand-primary-dark;
    }

    &:focus {
      //box-shadow: 0 0 8px $color-brand-primary;
    }

    &:focus:hover,
    &:focus:active {
      //border: 1px solid $color-brand-primary !important;
    }
*/
  }

  &--tertiary {
    background: $color-brand-tertiary;
    background: -webkit-gradient(linear, left top, left bottom, from($color-brand-tertiary-light), to($color-brand-tertiary-dark));
    background: -webkit-linear-gradient(top, $color-brand-tertiary-light, $color-brand-tertiary-dark);
    background: -moz-linear-gradient(top, $color-brand-tertiary-light, $color-brand-tertiary-dark);
    background: -ms-linear-gradient(top, $color-brand-tertiary-light, $color-brand-tertiary-dark);
    background: -o-linear-gradient(top, $color-brand-tertiary-light, $color-brand-tertiary-dark);
    //box-shadow: inset 0px -2px 0px 0px darken($color-brand-tertiary-dark, $color-variance);
    color: $color-brand-primary-dark !important;
    text-shadow: none;

    &:hover,
    &.btn--hover {
      background: -webkit-gradient(linear, left bottom, left top, from($color-brand-tertiary-light), to($color-brand-tertiary-dark));
      background: -webkit-linear-gradient(bottom, $color-brand-tertiary-light, $color-brand-tertiary-dark);
      background: -moz-linear-gradient(bottom, $color-brand-tertiary-light, $color-brand-tertiary-dark);
      background: -ms-linear-gradient(bottom, $color-brand-tertiary-light, $color-brand-tertiary-dark);
      background: -o-linear-gradient(bottom, $color-brand-tertiary-light, $color-brand-tertiary-dark);
      box-shadow: none;
    }

    &:active,
    &.btn--active {
      background: -webkit-gradient(linear, left bottom, left top, from($color-brand-tertiary-light), to($color-brand-tertiary-dark));
      background: -webkit-linear-gradient(bottom, $color-brand-tertiary-light, $color-brand-tertiary-dark);
      background: -moz-linear-gradient(bottom, $color-brand-tertiary-light, $color-brand-tertiary-dark);
      background: -ms-linear-gradient(bottom, $color-brand-tertiary-light, $color-brand-tertiary-dark);
      background: -o-linear-gradient(bottom, $color-brand-tertiary-light, $color-brand-tertiary-dark);
      box-shadow: inset 0px 2px 0px 0px darken($color-brand-tertiary-dark, $color-variance);
    }

    &:active {
      //box-shadow: inset 0px 2px 0px 0px $color-brand-primary-dark;
    }

    &:focus {
      //box-shadow: 0 0 8px $color-brand-primary;
    }

    &:focus:hover,
    &:focus:active {
      //border: 1px solid $color-brand-primary !important;
    }

  }

  &--ghost {
    background: none;
    border: 2px solid $color-action-primary;
    color: $color-action-primary !important;
    text-shadow: none;

    &.btn--secondary {
      border: 2px solid $color-action-secondary;
      color: $color-action-secondary !important;
    }

    &.btn--tertiary {
      border: 2px solid $color-grey-dark;
      color: $color-action-secondary !important;
      font-family: $font-body;
      font-size: 0.875em;
      transition: 0.3s;
    }

    &:hover,
    &.btn--hover {
      background: none;
      border: 2px solid $color-action-primary-dark;
      box-shadow: none;
      color: $color-action-primary-dark !important;
      padding: 0.55em 1em 0.45em;

      &.btn--secondary {
        border: 2px solid $color-action-secondary-dark;
        color: $color-action-secondary-dark !important;
      }

      &.btn--tertiary {
        border: 2px solid $color-action-secondary;
        color: $color-action-secondary !important;
      }
    }
/*
    &:active,
    &.btn--active {
      background: none;
      border: 2px solid $color-brand-primary;
      box-shadow: none;
      color: $color-brand-primary;
      padding: 0.55em 1em 0.45em;

      &.btn--secondary {
        border: 2px solid $color-action-secondary;
        color: $color-action-secondary;
      }

      &.btn--tertiary {
        border: 2px solid $color-action-secondary;
        color: $color-white;
        padding: 0.5em 1em 0.5em;
      }
    }*/

    &:active,
    &.btn--active {
      background: $color-action-primary-dark;
      border: 2px solid $color-action-primary-dark;
      box-shadow: none;
      color: $color-white !important;

      &.btn--secondary {
        background: $color-action-secondary-dark;
        border: 2px solid $color-action-secondary-dark;
        color: $color-white !important;
      }

      &.btn--tertiary {
        background: $color-action-secondary;
        border: 2px solid $color-action-secondary;
        color: $color-white !important;
      }
    }
/*
    &:active {
      //box-shadow: inset 0px 2px 0px 0px $color-brand-primary-dark;
    }

    &:focus {
      //box-shadow: 0 0 8px $color-brand-primary;
    }

    &:focus:hover,
    &:focus:active {
      //border: 1px solid $color-brand-primary !important;
    }
*/
  }

  &--inline {
    padding: 0.75em 1em 0.65em;

    &:hover,
    &.btn--hover {
      padding: 0.8em 1em 0.6em;
    }

    &:active,
    &.btn--active {
      padding: 0.9em 1em 0.5em;
    }
  }

  &--inline,
  &--datepicker {
    clear: none;
    float: left;
    margin-right: 0;
    margin-top: 0.45em;
  }

  &--datepicker,
  &--datepicker:hover {
    margin-left: 0.45em;
    margin-top: 0.25em;
    padding: 0.6em 0.5em;
    width: auto;
  }

  /*&--negative {
    background: none;
    border: 2px solid transparent;
    box-shadow: none;
    color: $color-warning;
    float: left;
    text-decoration: underline;
    text-shadow: none;

    &:hover {
      color: $color-warning-dark;
    }

    &:active {
      color: $color-warning-light;
    }

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      padding: 0.5em 1em;
    }

    &:focus:hover,
    &:focus:active {
      border: 2px solid transparent !important;
      outline: none;
    }
  }

  &--findaddress {
    position: absolute;
    left: 47.5%;
    bottom: 0;
    width: auto;

    &[disabled] {
      background: $color-grey;
      background: -webkit-gradient(linear, left top, left bottom, from($color-grey), to($color-grey-dark));
      background: -webkit-linear-gradient(top, $color-grey, $color-grey-dark);
      background: -moz-linear-gradient(top, $color-grey, $color-grey-dark);
      background: -ms-linear-gradient(top, $color-grey, $color-grey-dark);
      background: -o-linear-gradient(top, $color-grey, $color-grey-dark);
      box-shadow: inset 0px -2px 0px 0px darken($color-grey-dark, $color-variance);
      text-shadow: 0px -1px 0px $color-grey-dark;
    }

    &[disabled]:hover{
      box-shadow: inset 0px -2px 0px 0px darken($color-grey-dark, $color-variance);
    }

    &[disabled]:active {
      box-shadow: inset 0px -2px 0px 0px darken($color-grey-dark, $color-variance);
    }

    &[disabled]:focus {
      box-shadow: inset 0px -2px 0px 0px darken($color-grey-dark, $color-variance);
    }

    &[disabled]:focus:hover,
    &[disabled]:focus:active {
      border: 1px solid $color-grey !important;
    }
  }*/

}

//6@media screen and (min-width: 37.5em) {
@media screen and (min-width: 26.25em) {
  .btn {
    width: auto;

    &--findaddress {
      left: 72%;
    }
  }
}

/*

.button
{
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: .75em 1em;
  border: none;
  border-radius: .2em;
  cursor: pointer;
  background-color: #399FD3;
  color: #fff;
  text-shadow: 1px 1px 0 rgba(0,0,0,.35);

  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  text-decoration: none;

  transition: all 0.15s linear;

  &:hover
  {
    background-color: mix(#fff, #399FD3, 20%);
  }
}

  .button--large
  {
    font-size: 105%;
  }

  .button--small
  {
    font-size: 95%;
  }

.button-group
{
  .button
  {
    float: left;
    border-radius: 0;
  }

  .button:first-child
  {
    border-radius: .2em 0 0 .2em;
    border-right: 1px solid mix(#fff, #399FD3, 35%);
  }

  .button:last-child
  {
    border-radius: 0 .2em .2em 0;
    border-left: 1px solid mix(#fff, #399FD3, 35%);
  }
}*/


/* ========================
    Social Sharing Buttons
   ======================== */

  .socialshare {

  }

  a.socialshare__link {
    border: 1px solid $color-grey;
    border-radius: $radius;
    display: inline-block;
    font-size: 0.875em;
    height: 2em;
    margin-top: 0.25em;
    padding: 0.3em 0.6em;
    text-decoration: none;

    &--facebook {
      background: url('../assets/icons/share-facebook.svg') no-repeat 0.4em 0.3em;
      color: #3b5998;
      padding-left: calc(19px + 0.7em);

      &:hover {
        border-color: #3b5998;
      }
    }

    &--twitter {
      background: url('../assets/icons/share-twitter.svg') no-repeat 0.4em 0.3em;
      color: #1DA1F3;
      padding-left: calc(22px + 0.7em);

      &:hover {
        border-color: #1DA1F3;
      }
    }

    &--pinterest {
      background: url('../assets/icons/share-pinterest.svg') no-repeat 0.4em 0.3em;
      color: #BD2125;
      padding-left: calc(18px + 0.7em);

      &:hover {
        border-color: #BD2125;
      }
    }

    &--gplus {
      background: url('../assets/icons/share-gplus.svg') no-repeat 0.4em 0.35em;
      color: #C53929;
      padding-left: calc(26px + 0.7em);

      &:hover {
        border-color: #C53929;
      }
    }
  }

  .socialshare--large {

    a.socialshare__link {
      border: none;
      color: $color-white;
      font-size: 1.3em;
      line-height: 1em;
      height: 1.55em;
      padding: 0.35em 0.6em;
      margin-right: 0.25em;

      &--facebook {
        background: url('../assets/icons/share-facebook-reverse.svg') no-repeat 0.35em 0.3em #3b5998;
        padding-left: calc(19px + 0.8em);
      }

      &--twitter {
        background: url('../assets/icons/share-twitter-reverse.svg') no-repeat 0.4em 0.35em #1DA1F3;
        padding-left: calc(22px + 0.8em);
      }

      &--pinterest {
        background: url('../assets/icons/share-pinterest-reverse.svg') no-repeat 0.3em 0.25em #BD2125;
        padding-left: calc(18px + 0.8em);
      }

      &--gplus {
        background: url('../assets/icons/share-gplus-reverse.svg') no-repeat 0.35em 0.375em #C53929;
        padding-left: calc(26px + 0.8em);
      }
    }

  }












